import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import AppRoutes from './AppRoutes';
import './styles/reset.css';
import './styles/common.css';

function App() {
  return (
    <Router>
      <Header />
      <div className='main-body'>
        <AppRoutes />
        <ContactSection />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
