/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetElementProperty } from "../../hooks/useGetElementProperty";
import { useWindowSize } from "react-use";
import arrowImg from "../../assets/images/circled_dn_arrow_black_bg@2x.png";
import parse from 'html-react-parser';

interface AnchorLinkProps {
    children: string;
    onClick?: () => void;
    className?: string;
    widthPc?: string;
    widthSp?: string;
    heightPc?: string;
    heightSp?: string;
    color?: string;
    bgColor?: string;
    border?: string;
    hoverBorder?: string;
    borderRadius?: string;
    boxShadow?: string;
    hoverColor?: string;
    hoverBgColor?: string;
    fontSizePc?: string;
    fontSizeSp?: string;
    focusColor?: string;
    focusBgColor?: string;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    letterSpacing?: string;
    circleWidthPc?: string;
    circleWidthSp?: string;
    textMarginSp?: string;
    circlePositionLeftSp?: string;
    circlePositionBottomSp?: string;
};

export default function AnchorLink(props: AnchorLinkProps) {
    // Default values
    const defaultBtnWidthPc = '440px';
    const defaultBtnHeightPc = '64px';
    const defaultBtnWidthSp = '82vw';
    const defaultBtnHeightSp = '13.5vw';
    const defaultBorder = '2px solid #EAEAEA';
    const defaultHoverBorder = '2px solid #333';
    const defaultBorderRadius = '100px';
    const defaultBgColor = '#fff';
    const defaultColor = '#333';
    const defaultFontSizePc = '18px';
    const defaultFontSizeSp = '3.7vw';
    const defaultMarginTop = '0px';
    const defaultMarginBottom = '0px';
    const defaultMarginLeft = '0px';
    const defaultMarginRight = '0px';
    const defaultCircleWidthPc = '36px';
    const defaultCircleWidthSp = '8vw';
    const defaultTextMarginSp = '-10.667vw 2.467vw 0 2.6vw'
    const defaultCirclePositionLeftSp = '17vw';
    const defaultCirclePositionBottom = '3vw';

    const [, setIsHover] = useState(false);
    const [buttonRight, setButtonRight] = useState(0);
    const navigate = useNavigate();
    const targetRefButton = useRef(null);
    const { width, height } = useWindowSize();
    const circleMarginRightPc = 13;

    const { getElementProperty } = useGetElementProperty(targetRefButton);

    useEffect(() => {
        setButtonRight(getElementProperty('right'));
    }, [width, height, getElementProperty]);

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        } else {
            navigate("/contact");
        }
    };

    const buttonStyle = () => [
        css`
            width: ${props.widthPc || defaultBtnWidthPc};
            height: ${props.heightPc || defaultBtnHeightPc};
            margin-top: ${props.marginTop || defaultMarginTop};
            margin-bottom: ${props.marginBottom || defaultMarginBottom};
            margin-left: ${props.marginLeft || defaultMarginLeft};
            margin-right: ${props.marginRight || defaultMarginRight};
            border: ${props.border || defaultBorder};
            border-radius: ${props.borderRadius || defaultBorderRadius};
            background-color: ${props.bgColor || defaultBgColor};
            color: ${props.color || defaultColor};
            text-align: center;
            cursor: pointer;
            transition: background 0.5s;
            &:hover {
                border: ${props.hoverBorder || defaultHoverBorder};
            }
            &:active {
                border: ${props.border || defaultBorder};
                transition: background 0.2s;
            }
            @media (max-width: 768px) {
                width: ${props.widthSp || defaultBtnWidthSp};
                height: ${props.heightSp || defaultBtnHeightSp};
            }
        `
    ];

    const divStyle = () => [
        css`
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: 'Noto Sans JP';
            @media (max-width: 768px) {
                max-height: ${defaultBtnHeightSp};
            }
        `
    ];

    const textStyle = () => [
        css`
            font-size: ${props.fontSizePc || defaultFontSizePc};
            letter-spacing: ${props.letterSpacing};
            @media (max-width: 768px) {
                font-size: ${props.fontSizeSp || defaultFontSizeSp};
                font-weight: 500;
                margin: ${props.textMarginSp || defaultTextMarginSp}
            }
        `
    ];

    const circledArrow = () => [
        css`
            background-image: url(${arrowImg});
            background-size: cover;
            background-position: center;
            width: ${props.circleWidthPc || defaultCircleWidthPc};
            height: ${props.circleWidthPc || defaultCircleWidthPc};
            position: absolute;
            left: calc(${buttonRight + 'px - (' + ((props.circleWidthPc || defaultCircleWidthPc) + ' + ' + circleMarginRightPc + 'px)' )});
            @media (max-width: 768px) {
                width: ${props.circleWidthSp || defaultCircleWidthSp};
                height: ${props.circleWidthSp || defaultCircleWidthSp};
                max-height: 100px;
                left: calc(${buttonRight + 'px - ' + (props.circlePositionLeftSp || defaultCirclePositionLeftSp)});
                bottom: ${(props.circlePositionBottomSp || defaultCirclePositionBottom)};
            }
        `
    ];

    return (
        <>
            <button
                css={buttonStyle}
                onClick={handleClick}
                className={props.className}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                ref={targetRefButton}
            >
                <div css={divStyle}>
                    <span css={textStyle}>{parse(props.children)}</span>
                    <div css={circledArrow}></div>
                </div>
            </button>
        </>
    );
}
