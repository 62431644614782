/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import './Service.css';
import { TopicPath } from "../components/parts/TopicTitlePath";
import TopicTitlePath from "../components/parts/TopicTitlePath";
import Button from "../components/parts/Button";
import ImageBlock from "../components/parts/ImageBlock";
import PartnerBG from "../assets/images/service/partner-bg@3x.png";
import BgGraySP from "../assets/images/service/bg-gray-sp.png";
import { useService } from '../api/useService';
import { useEffect, Fragment } from 'react';
import Loading from '../components/parts/Loading';
import BgGray from "../assets/images/common/bg_grey@2x.png"
import { Space } from "../components/parts/SharedLayout";
import parse from 'html-react-parser';
// 事業内容詳細：一旦クローズ
// import { useNavigate } from "react-router-dom";

export const TopicPathData:TopicPath[] = [
    { name: '株式会社はばたーく', link: '/' },
    { name: '事業内容', link: '/service' },
];

export default function Service() {
    const { isLoading, serviceLists, getList, otherServiceLists, getOtherList } = useService();
    // 事業内容詳細：一旦クローズ
    // const navigate = useNavigate();

    useEffect(() => {
        getList();
        getOtherList();
    }, [getList, getOtherList])

    if (isLoading) {
        return (
            <Loading />
        )
    }

    // 事業内容詳細：一旦クローズ
    // const moveDetail = (id: number) => {
    //     navigate(`/service-details/${id}`);
    //     window.scrollTo({ top: 0 });
    // }

    return (
        <>
            {/* PC画面 */}
            <div className="pc-content">
                <TopicTitlePath
                    topicSub='BUSINESS'
                    topicMain='事業内容'
                    topicPath={TopicPathData}
                />
                
                <div className='service-top'>
                    <div className='service-sub-title'>
                        変化の激しい時代に柔軟かつ迅速な対応が求められる今、
                    </div>
                    <div className='service-title'>
                        企業のDXを推進するエコシステムによる<br/>
                        お客様の経営課題を解決します
                    </div>
                    <div className='service-text'>
                        Web開発における先端技術と豊富な経験からの高い技術力とローカル・グローバルのリソース活用や人材育成による豊富なリソースをお客さまのビジネスニーズに合わせてエコシステムでご提供いたします。社会の変化に迅速に対応し、お客様の持続的な成長とビジネスの発展を実現するためDX推進を支援します。
                    </div>
                    <div className='service-top-img'></div>
                </div>

                {
                    serviceLists.list.length > 0 &&
                    serviceLists.list.map((service, index) => (
                        <Fragment key={index}>
                            <ImageBlock backgroundImage={service.background_image.url_org} className='service-section'>
                                <div className='content' css={index % 2 !== 0 && css`flex-direction: row-reverse;`}>
                                    <div className='text-block'>
                                        <div className='title'>{service.subject}</div>
                                        <div className='sub-title'><div className='list-dot'></div>{service.point1_title}</div>
                                        <div className='text'>{parse(service.point1_text)}</div>
                                        <div className='sub-title'><div className='list-dot'></div>{service.point2_title}</div>
                                        <div className='text'>{parse(service.point2_text)}</div>
                                        {
                                            (service.point3_title && service.point3_text) && 
                                            <>
                                                <div className='sub-title'><div className='list-dot'></div>{service.point3_title}</div>
                                                <div className='text'>{parse(service.point3_text)}</div>
                                            </>
                                        }
                                        {/* 事業内容詳細：一旦クローズ */}
                                        {/* <Button
                                            marginTop='3.472vw'
                                            widthPc='29.169vw'
                                            heightPc='4.444vw'
                                            fontSizePc='1.111vw'
                                            onClick={() => moveDetail(service.topics_id)}
                                        >
                                            {service.subject}を詳しく見る
                                        </Button> */}
                                    </div>
                                    <ImageBlock backgroundImage={service.image1.url_org} className="image"></ImageBlock>
                                </div>
                            </ImageBlock>
                        </Fragment>
                    ))
                }

                <ImageBlock backgroundImage={PartnerBG} className='partner'>
                    <div className='sec-1 en-font' id="other-service-pc">Service, Solution</div>
                    <div className='sec-2'>
                        <div className='subsec-1'>サービス・ソリューション</div>
                        <div className='subsec-2'>日本が直面している少子高齢化や働き手不足の課題に対し、当社は果敢に向き合い、革新的なソリューションとイノベーションを通じて、持続可能な未来を目指します。多彩な製品やサービスで企業の成長と社会課題の解決を支援します。</div>
                    </div>
                    <div className='sec-3'>
                        {
                            otherServiceLists.list.length > 0 &&
                            otherServiceLists.list.map((otherService, index) => (
                                <Fragment key={index}>
                                    <div className='subsec'>
                                        <ImageBlock backgroundImage={otherService.image.url_org} className='sub-subsec-1'></ImageBlock>
                                        <div className='sub-subsec-2'>
                                            <p className='en-font title'>{otherService.subject}</p>
                                            <p className='text'>
                                                {otherService.point}
                                            </p>
                                        </div>
                                        {
                                            otherService.link ? (
                                                <Button 
                                                    widthPc='22.22vw'
                                                    heightPc='3.472vw'
                                                    fontSizePc='1.111vw'
                                                    circleWidthPc='2.038vw'
                                                    circleMarginRPc='0.694vw'
                                                    className='sub-subsec-3'
                                                    onClick={() => window.open(otherService.link)}
                                                >
                                                    詳しく見る
                                                </Button>
                                            ) : (
                                                <Space height='3.472vw' />
                                            )
                                        }
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                </ImageBlock>
            </div>

            {/* SP画面 */}
            <div className="sp-content">
                <TopicTitlePath
                    topicSub='BUSINESS'
                    topicMain='事業内容'
                    topicPath={TopicPathData}
                />

                <div className='service-top'>
                    <div className='service-sub-title'>
                        変化の激しい時代に柔軟かつ迅速な対応が求められる今、
                    </div>
                    <div className='service-title'>
                        企業のDXを推進するエコシステムによる<br/>
                        お客様の経営課題を解決します
                    </div>
                    <div className='service-text'>
                        Web開発における先端技術と豊富な経験からの高い技術力とローカル・グローバルのリソース活用や人材育成による豊富なリソースをお客さまのビジネスニーズに合わせてエコシステムでご提供いたします。社会の変化に迅速に対応し、お客様の持続的な成長とビジネスの発展を実現するためDX推進を支援します。
                    </div>
                    <div className='service-top-img'></div>
                </div>

                {
                    serviceLists.list.length > 0 &&
                    serviceLists.list.map((service, index) => (
                        <Fragment key={index}>
                            <ImageBlock backgroundImage={index % 2 === 0 ? BgGraySP : 'unset'} className='service-section'>
                                <div className='content'>
                                    <div className='title'>{service.subject}</div>
                                    <img src={service.image1.url_org} alt="" className="image" css={index % 2 !== 0 && css`background-image: url(${BgGray}); ${service.subject === 'ソリューション提案・営業支援' && 'object-view-box: inset(0px 0px 63px 0px);'}`} />
                                    <div className='sub-title'>
                                        <div className='list-dot'></div>
                                        <div>{service.point1_title}</div>
                                    </div>
                                    <div className='text'>{parse(service.point1_text)}</div>
                                    <div className='sub-title'>
                                        <div className='list-dot'></div>
                                        <div>{service.point2_title}</div>
                                    </div>
                                    <div className='text'>{parse(service.point2_text)}</div>
                                    {
                                        (service.point3_title && service.point3_text) && (
                                            <>
                                                <div className='sub-title'>
                                                    <div className='list-dot'></div>
                                                    <div>{service.point3_title}</div>
                                                </div>
                                                <div className='text'>{parse(service.point3_text)}</div>
                                            </>
                                        )
                                    }
                                    {/* 事業内容詳細：一旦クローズ */}
                                    {/* <Button 
                                        marginTop='13.87vw'
                                        marginBottom='15.414vw'
                                        widthSp='89.33vw'
                                        heightSp='13.33vw'
                                        fontSizeSp='3.733vw'
                                        circleWidthSp='8vw'
                                        circleMarginRSp='2.667vw'
                                        onClick={() => moveDetail(service.topics_id)}
                                    >
                                        {service.subject}を詳しく見る
                                    </Button> */}
                                </div>
                            </ImageBlock>
                        </Fragment>
                    ))
                }

                <ImageBlock backgroundImage={PartnerBG} className='partner'>
                    <div className='sec-1' id="other-service-sp">
                        <div className='sub-title en-font'>Service, Solution</div>
                        <div className='title'>サービス・ソリューション</div>
                        <div className='text'>日本が直面している少子高齢化や働き手不足の課題に対し、当社は果敢に向き合い、革新的なソリューションとイノベーションを通じて、持続可能な未来を目指します。多彩な製品やサービスで企業の成長と社会課題の解決を支援します。</div>
                    </div>
                    <div className='sec-2'>
                        {
                            otherServiceLists.list.length > 0 &&
                            otherServiceLists.list.map((otherServiceList, index) => (
                                <Fragment key={index}>
                                    <div className='subsec'>
                                        <ImageBlock backgroundImage={otherServiceList.image.url_org} className='sub-subsec-1'></ImageBlock>
                                        <div className='sub-subsec-2'>
                                            <p className='en-font title'>{otherServiceList.subject}</p>
                                            <p className='text'>
                                                {otherServiceList.point}
                                            </p>
                                        </div>
                                        {
                                            otherServiceList.link &&
                                            <Button 
                                                widthSp='78.667vw'
                                                heightPc='13.33vw'
                                                fontSizePc='3.733vw'
                                                circleWidthPc='8vw'
                                                circleMarginRPc='2.667vw'
                                                onClick={() => window.open(otherServiceList.link)}
                                            >
                                                詳しく見る
                                            </Button>
                                        }
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                </ImageBlock>
            </div>
        </>
    );
}