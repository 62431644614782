/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

type TextAreaProps = {
    name?: string;
    hasError?: boolean;
};

export default function TextArea(props: TextAreaProps) {
    const style = css`
        width: 100%;
        height: 16.667vw;
        border: 0.139vw solid ${props.hasError ? '#E84040' : '#EAEAEA'};
        border-radius: 0.417vw;
        resize: none;

        &:focus {
            outline: 0.139vw solid #EAEAEA;
        }

        @media (max-width: 768px) {
            height: 78.667vw;
            border-width: 0.533vw;
            border-radius: 1.6vw;
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
        }
    `;

    return (
        <>
            <textarea css={style} name={props.name} />
        </>
    );
}