import { apiClient } from "../lib/apiClient";
import { useState } from "react";
import { toast } from "react-toastify";

export type SubmitDataType = {
    // お問い合わせ種別
    ext_01: string;
    // ご氏名
    ext_02: string;
    // ご氏名（フリガナ）
    ext_03: string;
    // メールアドレス
    email: string;
    // 会社名
    ext_05: string;
    // 部署名
    ext_06: string;
    // 役職名
    ext_07: string;
    // 電話番号
    ext_08: string;
    // お問い合わせ詳細
    ext_09: string;
    // 新製品・サービスなどの各種新着情報のメール配信
    ext_10: string;
    // 個人情報の取り扱いについて同意する
    ext_11: string;
}

export const useContact = () => {
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

    const successToast = () => {
        toast.success('送信に成功しました。');
    }

    const failedToast = () => {
        toast.error('送信に失敗しました。');
    }

    const post = async (submitData: SubmitDataType) => {
        try {
            await apiClient.post('/rcms-api/1/contact', { ...submitData });
            successToast();
            setIsSubmitSuccess(true);
        } catch (error) {
            failedToast();
            setIsSubmitSuccess(false);
            console.error('Error:', error);
        }
    }

    return { isSubmitSuccess, setIsSubmitSuccess, post };
};