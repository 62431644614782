import './SolutionDetails.css'
import TopicTitlePath from '../components/parts/TopicTitlePath';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import parse from 'html-react-parser';
import NotFound from '../components/NotFound';
import { useSolution } from '../api/useSolution';
import Button from '../components/parts/Button';
import Loading from '../components/parts/Loading';
import { Space, HStack } from '../components/parts/SharedLayout';
import { mediaQuery, useMediaQuery } from '../hooks/useMediaQuery';
import Category from '../components/parts/Category';

export const SolutionPoint = (props: { points: string[] }) => {
    return (
        <div className='point-area'>
            <div className='point-label en-font'>POINT</div>
                {props.points.map((point: string, index: number) =>
                    <div className='flex point-wrap' key={index}>
                        <div className='yel-point'></div>
                        <div className='point-text'>{point}</div>
                    </div>
                )}
        </div>
    );
};

export default function SolutionDetails() {
    const { id } = useParams<{ id: string }>();
    const { solutionDetails, getDetail, isLoading } = useSolution();
    const isSP = useMediaQuery(mediaQuery.sp);

    const navigate = useNavigate();
    const handleBackToList = () => {
        navigate('/solution');
        window.scrollTo({ top: 0 });
    };

    useEffect(() => {
        getDetail(Number(id));
    }, [getDetail, id]);

    if (isLoading) {
        return (
            <Loading />
        );
    }

    if (!solutionDetails.details.topics_id) {
        return (
            <>
                <NotFound link='/solution'>ソリューションに戻る</NotFound>
            </>
        );
    }

    return (
        <>
            {
                <div>
                    <TopicTitlePath topicMain={solutionDetails.details.subject} topicSub='SOLUTION' topicPath={[{ name: '株式会社はばたーく', link: '/' }, { name: 'ソリューション', link: '/solution' }, { name: solutionDetails.details.subject, link: `/solution-details/${id}` }]} />
                    <div className='topic-1'>
                        <div className='subject'>{solutionDetails.details.subject}</div>
                        {
                            solutionDetails.details.subtitle &&
                            <div className='subtitle'>{solutionDetails.details.subtitle}</div>
                        }
                        <img className='eyecatch' src={solutionDetails.details.image.url_org} alt="" />
                        <div className='date-and-category'>
                            {
                                solutionDetails.details.ymd &&
                                <div className='date en-font'>{solutionDetails.details.ymd.split('-').join('.')}</div>
                            }
                            <HStack className='sol-tag-area'>
                                {
                                    solutionDetails.details.categories.length > 0 &&
                                    solutionDetails.details.categories.map((category, index) => (
                                        <Category key={index} label={category.label} />
                                    ))
                                }
                            </HStack>
                        </div>
                        
                        {
                            (solutionDetails.details.points.length > 0) &&
                            <>
                                <Space height={`${isSP ? '14vw' : '5vw'}`} />
                                <SolutionPoint points={solutionDetails.details.points} />
                            </>
                        }
                    </div>
                    <div className='topic-2'>
                        {
                            (solutionDetails.details.contents1 || solutionDetails.details.contents2 || solutionDetails.details.contents3) &&
                                <div>
                                    <div>{parse(solutionDetails.details.contents1)}</div>
                                    {
                                        (solutionDetails.details.highlights_subject && solutionDetails.details.highlights_text) &&
                                        <div className='white-space flex-col'>
                                            <div className='flex header'>
                                                <div className='yellow-point-m margin-t'></div>
                                                <div className='highlights-subject'>{solutionDetails.details.highlights_subject}</div>
                                            </div>
                                            <div className='highlights-text'>{solutionDetails.details.highlights_text}</div>
                                        </div>
                                    }
                                </div>
                        }
                        <div className='back-btn'>
                            {
                                isSP ? (
                                    <Button onClick={handleBackToList} isBackArrow={true} widthPc='320px' heightPc='50px' circleMarginRPc='278px' circleWidthPc='30px' circleMarginRSp='70.667vw' marginTop='14vw'>ソリューション一覧に戻る</Button>
                                ) : (
                                    <Button onClick={handleBackToList} isBackArrow={true} widthPc='370px' heightPc='50px' circleMarginRPc='278px' circleWidthPc='30px' circleMarginRSp='70.667vw' marginTop='5vw' >ソリューション一覧に戻る</Button>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}