/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import './RecruitDetails.css'
import TopicTitlePath, { TopicPath } from '../components/parts/TopicTitlePath';
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import ImageAndTextContainer from '../components/parts/ImageAndTextContainer';
import DailyTimeSchedule from '../components/parts/DailyTimeSchedule';
import NotFound from '../components/NotFound';
import { useMediaQuery, mediaQuery } from '../hooks/useMediaQuery';
import { useRecruit, MemberDetailType } from '../api/useRecruit';
import Loading from '../components/parts/Loading';
import RecruitMemberCard from '../components/RecruitMemberCard';

export default function RecruitDetails() {
    const { id } = useParams<{ id: string }>();
    const scheduleWrapRef1 = useRef<HTMLDivElement>(null);
    const scheduleWrapRef2 = useRef<HTMLDivElement>(null);
    const scheduleWrapRef3 = useRef<HTMLDivElement>(null);
    const scheduleWrapRef4 = useRef<HTMLDivElement>(null);
    const scheduleWrapRef5 = useRef<HTMLDivElement>(null);
    const scheduleWrapRef6 = useRef<HTMLDivElement>(null);
    const isSP = useMediaQuery(mediaQuery.sp);
    const { isLoading, memberDetails, getDetail, memberList, getList } = useRecruit();

    useEffect(() => {
        if (id) {
            getDetail(id);
        }
        getList();
    }, [getDetail, id, getList]);
    

    const moveDetail = (id: number) => {
        getDetail(String(id));
        getList();
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (!memberDetails.details.topics_id) {
        return (
            <NotFound link='/recruit'>採用ページへ戻る</NotFound>
        )
    }

    const TopicPathData: TopicPath[] = [
        { name: '株式会社はばたーく', link: '/' },
        { name: '採用情報', link: '/recruit' },
        { name: `${'社員インタビュー ' + memberDetails.details.occupation + ' ' + memberDetails.details.name}`, link: `/recruit-details/${id}` },
    ];

    return (
        <>
            <div className="">
                <TopicTitlePath topicSub='INTERVIEW' topicMain='社員インタビュー' topicPath={TopicPathData} />
                {
                    <>
                        {
                            !isSP ? (
                                <div className='main-img-area' css={css`background-image: url(${memberDetails.details.main_image.url_org})`}>
                                    <div className='hotword'>{memberDetails.details.hotword}</div>
                                    <div className='profile-card'>
                                        <div className='name-area'>
                                            <div className='yellow-point-m'></div>
                                            <div className='name'>{memberDetails.details.name}</div>
                                            <div className='department'>
                                                <div className='gray-hyphen'></div>
                                                {memberDetails.details.occupation + ' ' + memberDetails.details.entry_year + '年入社'}
                                            </div>
                                        </div>
                                        <div className='introduction'>{memberDetails.details.intro}</div>
                                    </div>
                                </div>
                            ) : (
                                <div className='main-img-area-sp'>
                                    <img src={memberDetails.details.main_image.url_org} alt="" className='main-img' />
                                    <div className='hotword'>{memberDetails.details.hotword}</div>
                                    <div className='profile-card'>
                                        <div className='name-area'>
                                            <div className='yellow-point-m'></div>
                                            <div className='name'>{memberDetails.details.name}</div>
                                            <div className='department'>
                                                <div className='gray-hyphen'></div>
                                                {memberDetails.details.occupation + ' ' + memberDetails.details.entry_year + '年入社'}
                                            </div>
                                        </div>
                                        <div className='introduction'>{memberDetails.details.intro}</div>
                                    </div>
                                </div>
                            )
                        }
                        <div className='details-area'>
                            <ImageAndTextContainer
                                data={{
                                    headText: memberDetails.details.contents1_subject,
                                    headImage: memberDetails.details.contents1_image.url_org,
                                    text1: memberDetails.details.contents1_text[0],
                                    text2: memberDetails.details.contents1_text[1],
                                    text3: memberDetails.details.contents1_text[2],
                                    text4: memberDetails.details.contents1_text[3],
                                    text5: memberDetails.details.contents1_text[4]
                                }}
                                dividerColor='yellow'
                            />
                            <ImageAndTextContainer
                                data={{
                                    headText: memberDetails.details.contents2_subject,
                                    headImage: memberDetails.details.contents2_image.url_org,
                                    text1: memberDetails.details.contents2_text[0],
                                    text2: memberDetails.details.contents2_text[1],
                                    text3: memberDetails.details.contents2_text[2],
                                    text4: memberDetails.details.contents2_text[3],
                                    text5: memberDetails.details.contents2_text[4]
                                }}
                                isImageLeft={true}
                                dividerColor='yellow'
                            />
                            <ImageAndTextContainer
                                data={{
                                    headText: memberDetails.details.contents3_subject,
                                    headImage: memberDetails.details.contents3_image.url_org,
                                    text1: memberDetails.details.contents3_text[0],
                                    text2: memberDetails.details.contents3_text[1],
                                    text3: memberDetails.details.contents3_text[2],
                                    text4: memberDetails.details.contents3_text[3],
                                    text5: memberDetails.details.contents3_text[4]
                                }}
                                dividerColor='yellow'
                            />
                        </div>
                        <div className='schedule-area'>
                            <div className='title-en en-font'>DAILY TIME SCHEDULE</div>
                            <div className='title-jp'>１日の流れ</div>
                            <div className='white-card'>
                                <DailyTimeSchedule data={{ time: memberDetails.details.time_schedule1[0], subject: memberDetails.details.time_schedule1[1], detail: memberDetails.details.time_schedule1[2] }} targetRef={scheduleWrapRef1} />
                                <DailyTimeSchedule data={{ time: memberDetails.details.time_schedule2[0], subject: memberDetails.details.time_schedule2[1], detail: memberDetails.details.time_schedule2[2] }} targetRef={scheduleWrapRef2} />
                                <DailyTimeSchedule data={{ time: memberDetails.details.time_schedule3[0], subject: memberDetails.details.time_schedule3[1], detail: memberDetails.details.time_schedule3[2] }} targetRef={scheduleWrapRef3} />
                                <DailyTimeSchedule data={{ time: memberDetails.details.time_schedule4[0], subject: memberDetails.details.time_schedule4[1], detail: memberDetails.details.time_schedule4[2] }} targetRef={scheduleWrapRef4} />
                                <DailyTimeSchedule data={{ time: memberDetails.details.time_schedule5[0], subject: memberDetails.details.time_schedule5[1], detail: memberDetails.details.time_schedule5[2] }} targetRef={scheduleWrapRef5} />
                                <DailyTimeSchedule data={{ time: memberDetails.details.time_schedule6[0], subject: memberDetails.details.time_schedule6[1], detail: memberDetails.details.time_schedule6[2] }} targetRef={scheduleWrapRef6} disableArrowDot={true} />
                            </div>
                        </div>
                        <div className='other-interview-area'>
                            <div className='title-en en-font'>OTHER INTERVIEW</div>
                            <div className='title-jp'>他の社員インタビューを見る</div>
                            <div className={`${isSP ? 'flex-col' : 'flex'}`}>
                                {
                                    memberList.list &&
                                    memberList.list.filter(member => member.topics_id !== parseInt(id || '', 10))
                                    .map((member: MemberDetailType, index: number) => {
                                        return (
                                            <RecruitMemberCard items={member} onClick={() => moveDetail(member.topics_id)} key={index} />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
}