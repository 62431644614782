/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToSection } from "./Link";

interface HeaderFooterButtonProps {
    children: string;
    isHeaderPC?: boolean;
    href: string;
    className?: string;
    width?: string;
    height?: string;
    borderColor?: string;
    borderRadius?: string;
    bgColor?: string;
    hoverBgColor?: string;
    color?: string;
    hoverColor?: string;
    fontSize?: string;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    paddingTop?: string;
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
};

export default function Button(props: HeaderFooterButtonProps) {
    // Default values
    const defaultWidth = 'fit-content';
    const defaultHeight = 'fit-content';
    const defaultBorderColor = '#333';
    const defaultBorderRadius = '100px';
    const defaultBgColor = '#333';
    const defaultHoverBgColor = '#fff';
    const defaultColor = '#fff';
    const defaultHoverColor = '#333';
    const defaultFontSize = '15px';
    const defaultMarginTop = '0';
    const defaultMarginBottom = '0';
    const defaultMarginLeft = '0';
    const defaultMarginRight = '0';
    const defaultPaddingTop = '8px';
    const defaultPaddingBottom = '8px';
    const defaultPaddingLeft = '35px';
    const defaultPaddingRight = '35px';

    const [, setIsHover] = useState(false);
    const [, setIsClicked] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        if (props.isHeaderPC) {
            scrollToSection(props.href);
        } else {
            navigate(props.href);
            setTimeout(() => {
                window.scrollTo({ top: 0 });
            }, 100)
            setIsClicked(true);
            setTimeout(() => setIsClicked(false), 200);
        }
    };

    const buttonStyle = () => [
        css`
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            width: ${props.width || defaultWidth};
            height: ${props.height || defaultHeight};
            text-align: center;
            cursor: pointer;
            margin-top: ${props.marginTop || defaultMarginTop};
            margin-bottom: ${props.marginBottom || defaultMarginBottom};
            margin-left: ${props.marginLeft || defaultMarginLeft};
            margin-right: ${props.marginRight || defaultMarginRight};
            padding-top: ${props.paddingTop || defaultPaddingTop};
            padding-bottom: ${props.paddingBottom || defaultPaddingBottom};
            padding-left: ${props.paddingLeft || defaultPaddingLeft};
            padding-right: ${props.paddingRight || defaultPaddingRight};
            border-style: solid;
            border-width: 2px;
            border-color: ${props.borderColor || defaultBorderColor};
            border-radius: ${props.borderRadius || defaultBorderRadius};
            background-color: ${props.bgColor || defaultBgColor};
            font-size: ${props.fontSize || defaultFontSize};
            color: ${props.color || defaultColor};
            transition: background 0.5s;
            &:hover {
                color: ${props.hoverColor || defaultHoverColor};
                background-color: ${props.hoverBgColor || defaultHoverBgColor};
            }
            &:active {
                color: ${props.color || defaultColor};
                background-color: ${props.bgColor || defaultBgColor};
                transition: background 0.8s;
            }
        `
    ];

    return (
        <>
            <div
                css={buttonStyle}
                onClick={handleClick}
                className={props.className}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                {props.children} 
                <span className="en-font">&nbsp;&nbsp;→</span>
            </div>
        </>
    );
}
