import './Contact.css'
import TopicTitlePath, { TopicPath } from '../components/parts/TopicTitlePath';
import ContactFormRow from '../components/ContactFormRow';
import CheckBox from '../components/parts/CheckBox';
import { Typography, VStack } from '../components/parts/SharedLayout';
import { FormEventHandler, useRef, useState } from 'react';
import { useContact, SubmitDataType } from '../api/useContact';
import { useContactFormValidation } from '../hooks/useContactFormValidation';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type ErrorMessage = {
    ext_01: string;
    ext_02: string;
    ext_03: string;
    email: string;
    ext_05: string;
    ext_08: string;
    ext_09: string;
    ext_10: string;
    ext_11: string;
}

const validationErrorToast = () => {
    toast.error('入力に誤りがあります。');
}

export default function Contact() {
    const topicPath: TopicPath[] = [
        { name: '株式会社はばたーく', link: '/' },
        { name: 'お問い合わせ', link: '/contact' },
    ];
    const { post } = useContact();
    const [isSending, setIsSending] = useState<boolean>(false);
    const { countError, errorMsg } = useContactFormValidation();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage>();
    const cardRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        setIsSending(true);
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const contactType          = form.get('contactType');
        const userName             = form.get('userName');
        const userNameKana         = form.get('userNameKana');
        const mail                 = form.get('mail');
        const companyName          = form.get('companyName');
        const department           = form.get('department');
        const jobTitle             = form.get('jobTitle');
        const tel                  = form.get('tel');
        const contactDetail        = form.get('contactDetail');
        const doReceiveNewReleases = form.get('doReceiveNewReleases');
        const personalInfo         = form.get('personalInfo');

        const formData: SubmitDataType = {
            ext_01: String(contactType),
            ext_02: String(userName),
            ext_03: String(userNameKana),
            email: String(mail),
            ext_05: String(companyName),
            ext_06: String(department),
            ext_07: String(jobTitle),
            ext_08: String(tel),
            ext_09: String(contactDetail),
            ext_10: String(doReceiveNewReleases),
            ext_11: String(personalInfo)
        }

        const errorCount = countError(formData);
        if (errorCount > 0) {
            validationErrorToast();
            setIsSending(false);
            setErrorMessage(errorMsg);
            if (formRef.current) {
                window.scrollTo({
                    top: formRef.current.getBoundingClientRect().top + window.pageYOffset,
                    behavior: 'smooth'
                })
            }
        } else {
            post(formData);
            if (formRef.current) {
                formRef.current.reset();
            }
            setErrorMessage(errorMsg);
            setIsSending(false);
        }
    };

    return (
        <>
            <div className="">
                <TopicTitlePath topicSub='CONTACT' topicMain='お問い合わせ' topicPath={topicPath} />
                <div className='contact-section' ref={cardRef}>
                    <form className='contact-card' onSubmit={handleSubmit} ref={formRef}>
                        <ContactFormRow isRequired title='お問い合わせ種別' inputType='radio' name='contactType'
                                        errorMsg={errorMessage && errorMessage.ext_01}
                                        inputItems={[
                                            { id: 1, label: 'DX・システム開発のご相談' },
                                            { id: 2, label: 'ご協業（SES/SIS）について' },
                                            { id: 3, label: 'リクルート' },
                                            { id: 4, label: 'その他' }
                                        ]} />
                        <ContactFormRow isRequired title='ご氏名' inputType='textbox' name='userName' errorMsg={errorMessage && errorMessage.ext_02} />
                        <ContactFormRow isRequired title='ご氏名（フリガナ）' inputType='textbox' name='userNameKana' errorMsg={errorMessage && errorMessage.ext_03} />
                        <ContactFormRow isRequired title='メールアドレス' inputType='textbox' name='mail' errorMsg={errorMessage && errorMessage.email} />
                        <ContactFormRow isRequired title='会社名' inputType='textbox' name='companyName' errorMsg={errorMessage && errorMessage.ext_05} />
                        <ContactFormRow title='部署名' inputType='textbox' name='department' />
                        <ContactFormRow title='役職名' inputType='textbox' name='jobTitle' />
                        <ContactFormRow isRequired title='電話番号' inputType='textbox' name='tel' caution='※半角数字でハイフンを含めてご記入ください（例 / 03-4530-0076）' errorMsg={errorMessage && errorMessage.ext_08} />
                        <ContactFormRow isRequired title='お問い合わせ詳細' inputType='textarea' name='contactDetail' errorMsg={errorMessage && errorMessage.ext_09} />
                        <ContactFormRow isRequired title='新製品・サービスなどの各種新着情報のメール配信' inputType='checkbox' name='doReceiveNewReleases'
                                        marginBottom100
                                        errorMsg={errorMessage && errorMessage['ext_10']}
                                        errorTextCenter
                                        inputItems={[
                                            { id: 1, label: '希望する' },
                                            {id: 2, label: '希望しない' }
                                        ]} />
                        <VStack className='personal-info-area'>
                            <Typography className='personal-info-label'>お問い合わせフォームにおける個人情報の取り扱いについて</Typography>
                            <CheckBox name='personalInfo' items={[{ id: 1, label: '個人情報の取り扱いについて同意する' }]} />
                            {
                                errorMessage &&
                                <Typography fontSize='0.903vw' letterSpacing='0.045vw' className='error-text'>{errorMessage.ext_11}</Typography>
                            }
                        </VStack>
                        <div className='submit-btn-wrap'>
                            <input type="submit" value="送信する　→" className='submit-btn' disabled={isSending} />
                            <ToastContainer
                                position="bottom-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}