import { useEffect, useState } from 'react'

export const mediaQuery = {
    sp: 'width <= 768px',
    pc: '768px < width',
}

export const useMediaQuery = (query: string) => {
    const formattedQuery = `(${query})`
    const [match, setMatch] = useState(matchMedia(formattedQuery).matches)

    useEffect(() => {
        const mediaQueryList = matchMedia(formattedQuery)

        if (mediaQueryList.media === 'not all' || mediaQueryList.media === 'invalid') {
            console.error(`useMediaQuery Error: Invalid media query`)
        }

        mediaQueryList.onchange = (e) => {
            setMatch(e.matches)
        }

        return () => {
            mediaQueryList.onchange = null
        }
    }, [formattedQuery, setMatch])

    return match;
}