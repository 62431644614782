import './About.css'
import TopicTitlePath from '../components/parts/TopicTitlePath';
import AnchorLink from '../components/parts/AnchorLink';
import { TopicPathData, ListData, CardData, AboutUsCardProps, NumbersCardData, CompanyOverviewData, CompanyOverviewType } from './AboutContext';
import Visual1 from '../assets/images/about/visual@2x.png';
import Visual2 from '../assets/images/about/visual-1@2x.png';
import Visual3 from '../assets/images/about/visual-2@2x.png';
import AboutLogo from '../assets/images/about/about-logo@2x.png'
import { useRef, useEffect, useState, Fragment } from 'react';
import CeoName from '../assets/images/about/ceo-name.png';
import { useWindowSize } from 'react-use';
import Illustrated1 from '../assets/images/about/mv-illustrated-1@2x.png'
import Illustrated2 from '../assets/images/about/mv-illustrated-2@2x.png'

const AboutCard = (props: AboutUsCardProps) => {
    return (
        <div className='about-card'>
            <img className='card-img' src={props.image} alt={props.title} />
            <div className='card-contents'>
                <div className='card-title'>{props.title}</div>
                <div className='card-title-en'>{props.titleEn}</div>
                <div className='card-detail'>{props.detail}</div>
            </div>
        </div>
    );
}

const OverViewTable = (props: CompanyOverviewType) => {
    return (
        <div className='overview-row'>
            <div className='overview-header'>{props.header}</div>
            <div className='overview-data'>
                {
                    props.image && (
                        <img className='overview-img' src={props.image} alt={props.header} />
                    )
                }
                <div className='data-wrap'>
                    {
                        props.rowData.map((d, j) => {
                            return (
                                <Fragment key={j}>
                                    {
                                        d.text2 && (
                                            <div className='sub-table'>
                                                <div>{d.text}</div>
                                                <div>{d.text2}</div>
                                            </div>
                                        )
                                    }
                                    {
                                        d.link && d.linkText && d.rearText && (
                                            <div>
                                                {d.text}
                                                <a href={d.link} target='blank'>{d.linkText}</a>
                                                {d.rearText}
                                            </div>
                                        )
                                    }
                                    {
                                        d.link && !d.linkText && !d.rearText && (
                                            <a href={d.link} target='blank' className={d.hasTab ? 'tab' : ''}>{d.text}</a>
                                        )
                                    }
                                    {
                                        !d.link && !d.text2 && (
                                            <div className={d.hasTab ? 'tab' : ''}>{d.text}</div>
                                        )
                                    }
                                </Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default function About() {
    const aboutUsRef = useRef<HTMLDivElement>(null);
    const ceoMsgRef = useRef<HTMLDivElement>(null);
    const companyOverviewRef = useRef<HTMLDivElement>(null);
    const overviewTableRef = useRef<HTMLDivElement>(null);
    const overviewRef = useRef<HTMLDivElement>(null);
    const aboutUsSpRef = useRef<HTMLDivElement>(null);
    const ceoMsgSpRef = useRef<HTMLDivElement>(null);
    const companyOverviewSpRef = useRef<HTMLDivElement>(null);
    const [mapHeight, setMapHeight] = useState<number>(0);
    const [mapWidth, setMapWidth] = useState<number>(0);
    const { width, height } = useWindowSize();

    useEffect(() => {
        if (overviewTableRef.current && companyOverviewRef.current && overviewRef.current) {
            const companyOverViewW = companyOverviewRef.current.getBoundingClientRect().width;
            const overViewW = overviewRef.current.getBoundingClientRect().width;
            const overviewTableH = overviewTableRef.current.getBoundingClientRect().height;
            const mapMarginBottom = 40;
            setMapWidth(companyOverViewW - overViewW);
            setMapHeight((overviewTableH - mapMarginBottom) / 2);
        }
    }, [width, height]);

    const smoothScroll = (target: React.RefObject<HTMLDivElement> | null) => {
        if (target?.current) {
            const { top } = target.current.getBoundingClientRect();
            window.scrollTo({
                top: top + window.pageYOffset,
                behavior: "smooth"
            });
        }
    }

    return (
        <>
            {/* PC画面 */}
            <div className="pc-content">
                <TopicTitlePath
                    topicSub='VISION'
                    topicMain='はばたーくとは？'
                    topicPath={TopicPathData}
                />
                <div className='about-top'>
                    <div className='en-font main'>CHANGE<span className='red'>S</span> TO GROW</div>
                    <div className='sub'>成長へ変革しつづける</div>
                    <div className='contents'>
                        私たちは "changes to grow" という信念を持ち、<br />
                        成長のために変革を続ける集団です。<br />
                        既成概念にとらわれず、新しい挑戦へ果敢に取り組むことを恐れない。<br />
                        困難をチャンスと捉え、<br />
                        それを受け入れながら成長を楽しむ姿勢を大切にしています。<br />
                        常に変わり続け、自己を進化させることで、<br />
                        私たちは未来に向けて前進し続けます。<br />
                        <div className='supplement'>
                            “<span className='red'>S</span>”が赤字で強調されていることには、継続的に変化し続けることを象徴している意味があります。<br />
                            複数形の「S」によって「一度きりの変化」ではなく、「何度も変わり続ける、常に進化する」という動的な姿勢を表しています。<br />
                        </div>
                    </div>
                    <div className='actions'>
                        <AnchorLink children='はばたーくってどんな会社？' onClick={() => smoothScroll(aboutUsRef)} widthPc='20.833vw' heightPc='4.167vw' fontSizePc='1.042vw' className='font-bold' letterSpacing='0.026vw' circleWidthPc='2.083vw' />&emsp;&thinsp;
                        <AnchorLink children='代表メッセージ' onClick={() => smoothScroll(ceoMsgRef)} widthPc='20.833vw' heightPc='4.167vw' fontSizePc='1.042vw' className='font-bold' letterSpacing='0.026vw' circleWidthPc='2.083vw' />&emsp;&thinsp;
                        <AnchorLink children='会社概要' onClick={() => smoothScroll(companyOverviewRef)} widthPc='20.833vw' heightPc='4.167vw' fontSizePc='1.042vw' className='font-bold' letterSpacing='0.026vw' circleWidthPc='2.083vw' />
                    </div>
                </div>
                <div className='about-us' ref={aboutUsRef}>
                    <div className='about-us-title'>
                        <div className='en-font about-us-sub'>ABOUT US</div>
                        <div className='about-us-main'>はばたーくってどんな会社？</div>
                    </div>
                    <div className='list-area'>
                        {
                            ListData.map((list, index) => (
                                <Fragment key={index}>
                                    <div className='list-wrap'>
                                        <div className='list-dot'></div>
                                        <div className='en-font list-title'>{list.title}</div>
                                        <div className='list-hyphen'></div>
                                        <div className='list-detail-wrap'>
                                            {
                                                list.detail.map((detail, i) => (
                                                    <Fragment key={i}>
                                                        <div className='list-detail'>{detail}</div>
                                                        {
                                                            i < list.detail.length - 1 && <br />
                                                        }
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                    <div className='aboutus-card-area'>
                        {
                            CardData.map((card, index) => (
                                <AboutCard image={card.image} title={card.title} titleEn={card.titleEn} detail={card.detail} key={index} />
                            ))
                        }
                    </div>
                </div>
                <div className='visual-area'>
                    <img className='visual1' src={Visual1} alt="" />
                    <img className='visual2' src={Visual2} alt="" />
                    <img className='visual1' src={Visual3} alt="" />
                </div>
                <div className='origin-area'>
                    <img src={AboutLogo} alt='株式会社はばたーくロゴ' />
                    <div className='origin-container'>
                        <div className='origin-title'>社名に込める思い</div>
                        <div className='origin-heading'>｢Have a talk (対話する)｣<span>を通じて</span><br />｢はばたく｣</div>
                        <div className='origin-detail'>お客様の課題や悩みに真摯に向き合い、しっかりと対話することが、私たちにとって最も重要なプロセスです。対話を通じて、お客様が抱える本質的な問題を見つけ出し、そのためのはばたく解決策をご提案する。<br />
                            この対話(Have a talk)から生まれる信頼と共感こそ、私たちのサービスの基盤です。お客様が成長し、飛躍していく過程で、私たちも共に成長し、未来への道を切り開いていきます。</div>
                    </div>
                </div>
                <div className='numbers-area'>
                    <div className='en-font numbers-title-en'>DATA</div>
                    <div className='numbers-title'>数字で見るはばたーく</div>
                    <div className='numbers-description'>社員の平均年齢や男女構成など、さまざまな数字からはばたーくの今をご紹介</div>
                    <div className='numbers-card-area'>
                        {
                            NumbersCardData.map((card, index) => (
                                <Fragment key={index}>
                                    <div className='numbers-card'>
                                        <img className='card-img' src={card.image} alt={card.title} />
                                        <div className='card-contents'>
                                            <div className='card-title'>{card.title}</div>
                                            <div className='card-numbers'>
                                                {
                                                    card.numbers.map((number, i) => {
                                                        return (
                                                            <Fragment key={i}>
                                                                {
                                                                    number['gender'] &&
                                                                    <span>{number['gender']}</span>
                                                                }
                                                                {
                                                                    number['leftNum'] &&
                                                                    <div className='left-num'>{number['leftNum']}</div>
                                                                }
                                                                {
                                                                    number['genderLeftNum'] &&
                                                                    <div className='gender-left-num'>{number['genderLeftNum']}</div>
                                                                }
                                                                {
                                                                    number['rightNum'] &&
                                                                    <div className='right-num'>{number['rightNum']}</div>
                                                                }
                                                                {
                                                                    number['unit'] &&
                                                                    <div className='unit'>{number['unit']}</div>
                                                                }
                                                                {
                                                                    number['unitJp'] &&
                                                                    <div className='unit-jp'>{number['unitJp']}</div>
                                                                }
                                                                {
                                                                    number['genderRightNum'] &&
                                                                    <div className='gender-right-num'>{number['genderRightNum']}</div>
                                                                }
                                                                {
                                                                    number['genderUnit'] &&
                                                                    <div className='gender-unit'>{number['genderUnit']}</div>
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
                <div className='ceo-msg-area' ref={ceoMsgRef}>
                    <div className='para1'>「はばたーく」は、2015年にアクログループの営業部門として創業し、社名に込めた<br />
                        「お客様と対話（Have a Talk）を大事にする」という理念のもと、多様なITソリュー<br />
                        ションをご提案してまいりました。</div>
                    <div className='para2'>創業から１０年が経ち、よりお客様のご要望に応えられるため、システム部の立ち上<br />
                        げやオフショア開発への展開、2024年にはグローバルテクノロジー宮崎株式会社の吸<br />
                        収合併を経て、ニアショア開発センターの設立に至りました。<br />
                        これにより、お客様に対してよりダイレクトに、より高品質なソリューションを提供で<br />
                        きる体制を整えました。</div>
                    <div className='para3'>私たちは今、第二創業期として新たな挑戦を迎えています。<br />
                        営業ノウハウに加え、コンサルティング、技術力、技術リソース、グローバルモデルを<br />
                        融合させることで、より広範な提案を実現し、お客様のデジタルトランスフォーメー<br />
                        ション（DX）を全力でサポートしてまいります。</div>
                    <div className='para4 en-font-semi-bold notranslate'>CHANGE<span className='red'>S</span> TO GROW</div>
                    <div className='para5'>変革が求められる時代において、私たち自身が最も変わり続ける必要があると考えてい<br />
                        ます。<br />
                        既存の枠にとらわれることなく、新しい挑戦を続けることで、私たちの成長を追求して<br />
                        まいります。</div>
                    <div className='para6'>関わるすべての方々が笑顔でいられるよう、夢と未来に向けて「はばたく」パートナー<br />
                        として、至誠一貫で取り組んでまいります。今後とも変わらぬご支援とご鞭撻を賜りま<br />
                        すよう、心よりお願い申し上げます。</div>
                    <div className='name'>代表取締役<br /><img src={CeoName} alt='中村和博' /></div>
                </div>

                <div className='company-overview-area' ref={companyOverviewRef}>
                    <div className='overview' ref={overviewRef}>
                        <div className='overview-title-en en-font'>COMPANY</div>
                        <div className='overview-title'>会社概要</div>
                        <div className='overview-table' ref={overviewTableRef}>
                            {
                                CompanyOverviewData.map((data, i) => (
                                    <OverViewTable id={data.id} header={data.header} rowData={data.rowData} image={data.image} key={i} />
                                ))
                            }
                        </div>
                    </div>
                    <div className='map'>
                        <iframe title='東京本社' width={mapWidth} height={mapHeight} style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12961.971504719491!2d139.774913!3d35.689487!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601889552d3aaaab%3A0x8f45e12fc89cfddf!2z44ix44Gv44Gw44Gf44O844GP!5e0!3m2!1sja!2sjp!4v1721286532414!5m2!1sja!2sjp"></iframe>
                        <iframe title='宮崎オフィス' width={mapWidth} height={mapHeight} style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54185.73244584597!2d131.423712!3d31.917239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3538b77e3a51b7f5%3A0x8b237eefd3c66d81!2z5qCq5byP5Lya56S-44Gv44Gw44Gf44O844GP!5e0!3m2!1sen!2sjp!4v1721287223855!5m2!1sen!2sjp"></iframe>
                    </div>
                </div>
            </div>

            {/* SP画面 */}
            <div className="sp-content">
                <TopicTitlePath
                    topicSub='VISION'
                    topicMain='はばたーくとは？'
                    topicPath={TopicPathData}
                />
                <div className='about-top'>
                    <div className='en-font main'>CHANGE<span className='red'>S</span> TO GROW</div>
                    <div className='sub'>成長へ変革しつづける</div>
                    <img className='illust1' src={Illustrated1} alt='' />
                    <div className='contents'>
                        私たちは <br />
                        "change<span className='red'>s</span> to grow" <br />
                        という信念を持ち、<br />
                        成長のために変革を続ける集団です。<br />
                        既成概念にとらわれず、<br />
                        新しい挑戦へ果敢に<br />
                        取り組むことを恐れない。<br />
                        困難をチャンスと捉え、<br />
                        それを受け入れながら<br />
                        成長を楽しむ姿勢を大切にしています。<br />
                        常に変わり続け、自己を進化させることで、<br />
                        私たちは未来に向けて前進し続けます。<br />
                        <div className='supplement'>
                            “<span className='red'>S</span>”が赤字で強調されていることには、<br />
                            継続的に変化し続けることを象徴している意味があります。<br />
                            複数形の「S」によって「一度きりの変化」ではなく、<br />
                            「何度も変わり続ける、常に進化する」という動的な姿勢を表しています。
                        </div>
                    </div>
                    <img className='illust2' src={Illustrated2} alt='' />
                    <div className='actions'>
                        <AnchorLink children='はばたーくってどんな会社？' onClick={() => smoothScroll(aboutUsSpRef)} widthSp='27vw' heightSp='33.067vw' fontSizeSp='3.56vw' className='font-bold' letterSpacing='0.09vw' circleWidthSp='8vw' borderRadius='2.667vw' />&emsp;&thinsp;
                        <AnchorLink children='代表<br/>メッセージ' onClick={() => smoothScroll(ceoMsgSpRef)} widthSp='27vw' heightSp='33.067vw' fontSizeSp='3.56vw' className='font-bold' letterSpacing='0.09vw' circleWidthSp='8vw' borderRadius='2.667vw' />&emsp;&thinsp;
                        <AnchorLink children='会社概要' onClick={() => smoothScroll(companyOverviewSpRef)} widthSp='27vw' heightSp='33.067vw' fontSizeSp='3.56vw' className='font-bold' letterSpacing='0.09vw' circleWidthSp='8vw' borderRadius='2.667vw' />
                    </div>
                </div>
                <div className='about-us' ref={aboutUsSpRef}>
                    <div className='about-us-title'>
                        <div className='en-font about-us-sub'>ABOUT US</div>
                        <div className='about-us-main'>はばたーくってどんな会社？</div>
                    </div>
                    <div className='list-area'>
                        {
                            ListData.map((list, index) => (
                                <Fragment key={index}>
                                    <div className='list-title-area'>
                                        <div className='list-dot'></div>
                                        <div className='en-font list-title'>{list.title}</div>
                                    </div>
                                    <div className='list-detail-area'>
                                        <div className='list-hyphen'></div>
                                        <div className='list-detail-wrap'>
                                            {
                                                list.detail.map((detail, i) => (
                                                    <Fragment key={i}>
                                                        <div className='list-detail' key={i}>{detail}</div>
                                                        {
                                                            i < list.detail.length - 1 && <br />
                                                        }
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                    <div className='aboutus-card-area'>
                        {
                            CardData.map((card, index) => (
                                <AboutCard image={card.image} title={card.title} titleEn={card.titleEn} detail={card.detail} key={index} />
                            ))
                        }
                    </div>
                </div>
                <div className='visual-area'>
                    <img className='visual1' src={Visual1} alt="" />
                    <img className='visual2' src={Visual2} alt="" />
                    <img className='visual3' src={Visual3} alt="" />
                </div>
                <div className='origin-area'>
                    <img src={AboutLogo} alt='株式会社はばたーくロゴ' />
                    <div className='origin-container'>
                        <div className='origin-title'>社名に込める思い</div>
                        <div className='origin-heading'>｢Have a talk(対話する)｣<span>を通じて</span>｢はばたく｣</div>
                        <div className='origin-detail'> お客様の課題や悩みに真摯に向き合い、しっかりと対話することが、私たちにとって最も重要なプロセスです。対話を通じて、お客様が抱える本質的な問題を理解し、そのためのはばたく解決策をご提案する。<br />
                            この対話(Have a talk)から生まれる信頼と共感こそ、私たちのサービスの基盤です。お客様が成長し、飛躍していく過程で、私たちも共に成長し、未来への道を切り開いていきます。</div>
                    </div>
                </div>
                <div className='numbers-area'>
                    <div className='en-font numbers-title-en'>DATA</div>
                    <div className='numbers-title'>数字で見るはばたーく</div>
                    <div className='numbers-description'>社員の平均年齢や男女構成など、さまざまな数字からはばたーくの今をご紹介</div>
                    <div className='numbers-card-area'>
                        {
                            NumbersCardData.map((card, index) => (
                                <div className={`numbers-card ${((index % 2) === 0) ? 'margin-r-4vw' : 'margin-r-0'}`} key={index}>
                                    <img className='card-img' src={card.image} alt={card.title} />
                                    <div className='card-contents'>
                                        <div className='card-title'>{card.title}</div>
                                        <div className='card-numbers'>
                                            {
                                                card.numbers.map((number, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            {
                                                                number['gender'] &&
                                                                <span>{number['gender']}</span>
                                                            }
                                                            {
                                                                number['leftNum'] &&
                                                                <div className='left-num'>{number['leftNum']}</div>
                                                            }
                                                            {
                                                                number['genderLeftNum'] &&
                                                                <div className='gender-left-num'>{number['genderLeftNum']}</div>
                                                            }
                                                            {
                                                                number['rightNum'] &&
                                                                <div className='right-num'>{number['rightNum']}</div>
                                                            }
                                                            {
                                                                number['unit'] &&
                                                                <div className='unit'>{number['unit']}</div>
                                                            }
                                                            {
                                                                number['unitJp'] &&
                                                                <div className='unit-jp'>{number['unitJp']}</div>
                                                            }
                                                            {
                                                                number['genderRightNum'] &&
                                                                <div className='gender-right-num'>{number['genderRightNum']}</div>
                                                            }
                                                            {
                                                                number['genderUnit'] &&
                                                                <div className='gender-unit'>{number['genderUnit']}</div>
                                                            }
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='ceo-msg-area' ref={ceoMsgSpRef}>
                    <div className='para1'>「はばたーく」は、2015年にアクログループの営業部門として創業し、社名に込めた「お客様と対話（Have a Talk）を大事にする」という理念のもと、多様なITソリューションをご提案してまいりました。</div>
                    <div className='para2'>創業から10年が経ち、私たちはシステム部の立ち上げやオフショア開発への展開、2024年にはグローバルテクノロジー宮崎株式会社の吸収合併を経て、ニアショア開発センターの設立に至りました。<br />これにより、お客様に対してよりダイレクトに、より高品質なソリューションを提供できる体制を整えました。</div>
                    <div className='para3'>私たちは今、第二創業期として新たな挑戦を迎えています。<br />営業ノウハウに加え、コンサルティング、技術力、技術リソース、グローバルモデルを融合させることで、より広範な提案を実現し、お客様のデジタルトランスフォーメーション（DX）を全力でサポートしてまいります。</div>
                    <div className='para4 en-font-semi-bold notranslate'>CHANGE<span className='red'>S</span> TO GROW</div>
                    <div className='para5'>変革が求められる時代において、私たち自身が最も変わり続ける必要があると考えています。<br />既存の枠にとらわれることなく、新しい挑戦を続けることで、私たちの成長を追求してまいります。</div>
                    <div className='para6'>関わるすべての方々が笑顔でいられるよう、夢と未来に向けて「はばたく」パートナーとして、至誠一貫で取り組んでまいります。今後とも変わらぬご支援とご鞭撻を賜りますよう、心よりお願い申し上げます。</div>
                    <div className='name'>代表取締役<img src={CeoName} alt='中村和博' /></div>
                </div>
                <div className='company-overview-area' ref={companyOverviewSpRef}>
                    <div className='overview'>
                        <div className='overview-title-en en-font'>COMPANY</div>
                        <div className='overview-title'>会社概要</div>
                        <div className='overview-table'>
                            {
                                CompanyOverviewData.map((data, i) => (
                                    <OverViewTable id={data.id} header={data.header} rowData={data.rowData} image={data.image} key={i} />
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='map'>
                    <iframe title='東京本社' width={'100%'} style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12961.971504719491!2d139.774913!3d35.689487!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601889552d3aaaab%3A0x8f45e12fc89cfddf!2z44ix44Gv44Gw44Gf44O844GP!5e0!3m2!1sja!2sjp!4v1721286532414!5m2!1sja!2sjp"></iframe>
                    <iframe title='宮崎オフィス' width={'100%'} style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54185.73244584597!2d131.423712!3d31.917239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3538b77e3a51b7f5%3A0x8b237eefd3c66d81!2z5qCq5byP5Lya56S-44Gv44Gw44Gf44O844GP!5e0!3m2!1sen!2sjp!4v1721287223855!5m2!1sen!2sjp"></iframe>
                </div>
            </div>
        </>
    );
}