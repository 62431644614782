import { apiClient } from "../lib/apiClient";
import { useState, useCallback } from "react";
import axios from "axios";

type SolutionListResponseApi = {
    errors: [];
    messages: [];
    list: SolutionListType[];
    pageInfo: PageInfo;
};

export type SolutionListType = {
    topics_id: number;
    ymd: string;
    contents: string;
    subject: string;
    categories: CategoryType[];
    image: {
        id: string,
        desc: string,
        url_org: string,
    }
};

type PageInfo = {
    pageNo: number;
    endPageNo: number;
    totalPageCnt: number;
};

type SolutionDetailsResponseApi = {
    errors: [];
    messages: [];
    details: SolutionDetailsType;
};

type SolutionDetailsType = {
    topics_id: number,
    ymd: string,
    contents: string,
    subject: string,
    categories: CategoryType[];
    subtitle: string,
    image: {
        id: string,
        desc: string,
        url_org: string
    },
    points: string[],
    contents1: string,
    contents2: string,
    contents3: string,
    highlights_subject: string,
    highlights_text: string
};

type SolutionCategoryType = {
    categories: CategoryType[],
};

export type CategoryType = {
    key: string;
    label: string;
    isSelected?: boolean;
}

export const useSolution = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [solutionList, setSolutionList] = useState<SolutionListResponseApi>({
        errors: [],
        messages: [],
        list: [],
        pageInfo: {
            pageNo: 0,
            endPageNo: 0,
            totalPageCnt: 0,
        }});
    const [solutionDetails, setSolutionDetails] = useState<SolutionDetailsResponseApi>({
        errors: [],
        messages: [],
        details: {
            topics_id: 0,
            ymd: '',
            contents: '',
            subject: '',
            categories: [],
            subtitle: '',
            image: {
                id: '',
                desc: '',
                url_org: '',
            },
            points: [],
            contents1: '',
            contents2: '',
            contents3: '',
            highlights_subject: '',
            highlights_text: '',
        }
    });
    const [categories, setCategories] = useState<CategoryType[]>();

    const getList = useCallback(async (currentPage: number, filterWord?: string) => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        await apiClient.get('/rcms-api/1/solution/list', {
            params: {
                pageID: currentPage || 1,
                filter: filterWord || '',
            },
            cancelToken: source.token,
        }).then((response) => {
            setSolutionList(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, [])

    const getDetail = useCallback(async (topicsId: number) => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        await apiClient.get(`/rcms-api/1/solution/detail/${topicsId}`, { cancelToken: source.token })
        .then((response) => {
            setSolutionDetails(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, [])

    const getPreview = useCallback(async (token: string) => {
        setIsLoading(true);
        await apiClient.get(`/rcms-api/1/solution/preview?preview_token=${token}`, {})
        .then((response) => {
            setSolutionDetails(response.data);
        }).catch ((error) => {
            console.error('Error:', error);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [])

    const getUsedCategory = useCallback(async () => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        await apiClient.get('/rcms-api/1/solution/category', { cancelToken: source.token })
        .then((response) => {
            let usedCategories: { key: string, label: string }[] = [];
            response.data.list.length > 0 &&
            response.data.list.map((item: SolutionCategoryType) =>
                item.categories.length > 0 && item.categories.map((category) =>
                    usedCategories.push(category)
                )
            )
            const uniqueCategories = Array.from(new Map(usedCategories.map(itemObject => [itemObject.key, itemObject])).values());
            setCategories(uniqueCategories);
        }).catch((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, [])

    return { isLoading, solutionList, getList, solutionDetails, getDetail, getPreview, getUsedCategory, categories, setCategories };
}