import './Recruit.css'
import ImgMain from '../assets/images/recruit/main-bg-people@2x.png';
import ImgMainLeftSp from '../assets/images/recruit/main-bg-illus-1@2x.png';
import ImgMainRightSp from '../assets/images/recruit/main-bg-illus-2@2x.png';
import WelfareIcon1 from '../assets/images/recruit/welfare-icon-1@2x.png';
import WelfareIcon2 from '../assets/images/recruit/welfare-icon-2@2x.png';
import WelfareIcon3 from '../assets/images/recruit/welfare-icon-3@2x.png';
import WelfareIcon4 from '../assets/images/recruit/welfare-icon-4@2x.png';
import { useEffect, useState } from 'react';
import { useRecruit } from '../api/useRecruit';
import RecruitMemberCard from '../components/RecruitMemberCard';

const RecruitSales = () => {
    const pathItems = [
        { year: '入社1〜2年目', text: '営業の基礎を習得し、業界や技術・テクノロジーについての理解を深めます。先輩社員からの指導のもとで、顧客やパートナーへの対応や提案活動を通じて営業スキルを磨きます。' },
        { year: '入社3〜4年目', text: '担当顧客を持ち、売上目標達成に向けた営業活動をリード。提案・商談から契約までの一連のプロセスを1人で対応し、顧客との関係構築や関わるエンジニアへのフォローアップも行います。自分の営業スタイルを確立し、結果を出すことが求められます。' },
        { year: '入社5〜9年目', text: 'チームリーダーとして、営業チームメンバーへの指導や管理を担当。重要なプロジェクトや大口顧客の対応を任されることが多くなり、戦略的な営業活動やチーム全体の成果を上げる役割を担います。' },
        { year: '入社10年目〜', text: '営業戦略の策定や新規事業の立ち上げに関与。企業の成長戦略に貢献し、部門やチーム全体の方向性を示すリーダーシップを発揮します。業界のトレンドを把握し、革新的な営業アプローチを推進します。' }
    ];

    return (
        <div className='ledger-content'>
            <div className='stairs-bubble'>
                <div className='stairs-bubble-1'><p>営業の基礎を習得し、業界理解を深めます。先輩指導のもと、顧客対応や提案スキルを磨きます。</p></div>
                <div className='stairs-bubble-2'><p>担当顧客を持ち、一人で提案から契約まで対応。顧客関係の構築やエンジニアフォローも行います。</p></div>
                <div className='stairs-bubble-3'><p>チームリーダーとして、メンバー指導や管理を担当。重要プロジェクトや大口顧客の対応をリードします。</p></div>
                <div className='stairs-bubble-4'><p>営業戦略の策定や新規事業立ち上げに貢献。リーダーシップを発揮し、成長戦略の推進を担います。</p></div>
            </div>
            <div className='visual'>
                <div className='visual-text-1'>
                    <span className='visual-bullet'></span>
                    <span>入社</span>
                </div>
                <div className='visual-text-2'>
                    <span className='visual-bullet'></span>
                    <span>3年目〜</span></div>
                <div className='visual-text-3'>
                    <span className='visual-bullet'></span>
                    <span>5年目〜</span></div>
                <div className='visual-text-4'>
                    <span className='visual-bullet'></span>
                    <span>10年目〜</span></div>
            </div>
            <div className='path-content'>
                {pathItems.map((item, index) => (
                    <div key={index} className='path-item'>
                        <div className='path-item-title'>
                            <span className='path-bullet'></span>
                            <span>{item.year}</span>
                        </div>
                        <div>
                            <div className='path-line'>
                                <div className='path-item-text'>{item.text}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
const RecruitEngineer = () => {
    const pathItems = [
        { year: '入社1〜2年目', text: '入社後、プログラミング研修、OJTによる実務経験を経て、IT基礎スキルを習得！' },
        { year: '入社3〜4年目', text: 'コーディング・テスト作業から設計工程作業などの次のステップへ。主体性を発揮し技術提案やリーダー業務も！' },
        { year: '入社5〜9年目', text: 'これまでの経験、自身の強みを活かした役割を担っていきます。例えば、プロジェクトリーダーとして、スケジュール管理や調整、開発メンバーへの指示、品質の担保等、プロジェクト全体を把握し推進したり、またはテクニカルリーダーとして、技術選定や設計方針等、プロジェクトの方向性の決定に関与したり、チーム全体の生産性向上を図ったりします。その他、要件定義工程等にも携わり、クライアントとのコミュニケーションをとる機会も多くなります。' },
        { year: '入社10年目〜', text: '大規模なプロジェクトのPMとして、全体を統括する機会も増えてきます。プロジェクトの進行管理だけでなく、メンバーアサインや会社としての収支部分の管理も行います。また会社としての技術部門全体の統括や、技術戦略の策定に関わり、新規事業の立ち上げにも携わって、ビジネス的な視点も持って経営に参画します。CTOや技術顧問など、経営陣の一員として会社の成長に貢献したり、エンジニアのキャリアパスを支援し、次世代のリーダー育成を行います。' }
    ];

    return (
        <div className='ledger-content'>
            <div className='stairs-bubble'>
                <div className='stairs-bubble-1'><p>入社後、プログラミング研修、OJTによる実務経験を経て、IT基礎スキルを習得！</p></div>
                <div className='stairs-bubble-2'><p>コーディング・テスト作業から設計工程作業などの次のステップへ。主体性を発揮し技術提案やリーダー業務も！</p></div>
                <div className='stairs-bubble-3'><p>リーダーシップの発揮とプロジェクト全体の管理、技術面のリーダーとしてのチームの牽引！</p></div>
                <div className='stairs-bubble-4'><p>会社の技術戦略での策定や、新規事業立上げ等にも携われます！</p></div>
            </div>
            <div className='visual'>
                <div className='visual-text-1'>
                    <span className='visual-bullet'></span>
                    <span>入社</span>
                </div>
                <div className='visual-text-2'>
                    <span className='visual-bullet'></span>
                    <span>3年目〜</span>
                </div>
                <div className='visual-text-3'>
                    <span className='visual-bullet'></span>
                    <span>5年目〜</span>
                </div>
                <div className='visual-text-4'>
                    <span className='visual-bullet'></span>
                    <span>10年目〜</span>
                </div>
            </div>
            <div className='path-content'>
                {pathItems.map((item, index) => (
                    <div key={index} className='path-item'>
                        <div className='path-item-title'>
                            <span className='path-bullet'></span>
                            <span>{item.year}</span>
                        </div>
                        <div>
                            <div className='path-line'>
                                <div className='path-item-text'>{item.text}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const RecruitSalesSp = () => {
    const pathItems = [
        { year: '入社1〜2年目', text: '営業の基礎を習得し、業界や技術・テクノロジーについての理解を深めます。先輩社員からの指導のもとで、顧客やパートナーへの対応や提案活動を通じて営業スキルを磨きます。', bubble: '営業の基礎を習得し、業界理解を深めます。先輩指導のもと、顧客対応や提案スキルを磨きます。' },
        { year: '入社3〜4年目', text: '担当顧客を持ち、売上目標達成に向けた営業活動をリード。提案・商談から契約までの一連のプロセスを1人で対応し、顧客との関係構築や関わるエンジニアへのフォローアップも行います。自分の営業スタイルを確立し、結果を出すことが求められます。', bubble: '担当顧客を持ち、一人で提案から契約まで対応。顧客関係の構築やエンジニアフォローも行います。' },
        { year: '入社5〜9年目', text: 'チームリーダーとして、営業チームメンバーへの指導や管理を担当。重要なプロジェクトや大口顧客の対応を任されることが多くなり、戦略的な営業活動やチーム全体の成果を上げる役割を担います。', bubble: 'チームリーダーとして、メンバー指導や管理を担当。重要プロジェクトや大口顧客の対応をリードします。' },
        { year: '入社10年目〜', text: '営業戦略の策定や新規事業の立ち上げに関与。企業の成長戦略に貢献し、部門やチーム全体の方向性を示すリーダーシップを発揮します。業界のトレンドを把握し、革新的な営業アプローチを推進します。', bubble: '営業戦略の策定や新規事業立ち上げに貢献。リーダーシップを発揮し、成長戦略の推進を担います。' }
    ];

    return (
        <div className='ledger-content'>
            <div className='path-content'>
                {pathItems.map((item, index) => (
                    <div key={index} className='path-item'>
                        <div className='path-item-title'>
                            <span className='path-bullet'></span>
                            <span>{item.year}</span>
                        </div>
                        <div>
                            <div className='path-line'>
                                <div className='path-item-text'>{item.text}</div>
                                <div className='path-visual-bubble'>
                                    <div className='path-visual'></div>
                                    <div className='stairs-bubble'>
                                        <div className='stairs-bubble-1'><p>{item.bubble}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
const RecruitEngineerSp = () => {
    const pathItems = [
        { year: '入社1〜2年目', text: '入社後、プログラミング研修、OJTによる実務経験を経て、IT基礎スキルを習得！', bubble: '入社後、プログラミング研修、OJTによる実務経験を経て、IT基礎スキルを習得！' },
        { year: '入社3〜4年目', text: 'コーディング・テスト作業から設計工程作業などの次のステップへ。主体性を発揮し技術提案やリーダー業務も！', bubble: 'コーディング・テスト作業から設計工程作業などの次のステップへ。主体性を発揮し技術提案やリーダー業務も！' },
        { year: '入社5〜9年目', text: 'リーダーシップの発揮とプロジェクト全体の管理、技術面のリーダーとしてのチームの牽引！', bubble: '営業の基礎を習得し、業界理解を深めます。先輩指導のもと、顧客対応や提案スキルを磨きます。' },
        { year: '入社10年目〜', text: '会社の技術戦略での策定や、新規事業立上げ等にも携われます！', bubble: '営業の基礎を習得し、業界理解を深めます。先輩指導のもと、顧客対応や提案スキルを磨きます。' }
    ];

    return (
        <div className='ledger-content'>
            <div className='path-content'>
                {pathItems.map((item, index) => (
                    <div key={index} className='path-item'>
                        <div className='path-item-title'>
                            <span className='path-bullet'></span>
                            <span>{item.year}</span>
                        </div>
                        <div>
                            <div className='path-line'>
                                <div className='path-item-text'>{item.text}</div>
                                <div className='path-visual-bubble'>
                                    <div className='path-visual'></div>
                                    <div className='stairs-bubble'>
                                        <div className='stairs-bubble-1'><p>{item.bubble}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const Flow = () => {
    const flowItems = [
        { step: 'STEP', number: '01', title: '書類選考', text: ['エントリーいただいた応募書類にて選考いたします。書類選考前にカジュアル面談も対応しています。カジュアル面談をご希望な方は、ぜひご相談ください！'] },
        { step: 'STEP', number: '02', title: '面接', text: ['1次面接：', '書類選考を通過された方は1次面接に進みます。面接は採用担当者または役員が担当いたします。ざっくばらんに雑談をしながら、履歴書・ご経歴書に沿って今までのご経験をお伺いします！リラックスしてぜひお話できればと思います。', '2次面接：', '1次面接を通過された方は2次面接に進みます。面接は所属部署の責任者、または代表取締役が担当いたします。今までのご経験、ご対応できることなど、より具体的にお話を伺えればと思います！2面接形式については平日19時以降の面接もご相談ください。お住まいの関係等でオンラインをご希望の場合は別途ご相談ください。'] },
        { step: 'STEP', number: '03', title: '合否決定', text: ['選考は面接後、大体1～2週間で合否決定いたします。合否のご連絡は基本的にはメールでのご連絡とさせていただいております。また内定の方には内定通知書を送付いたします。添付されている承諾書を期日までに返送いただきます。※ご希望がございましたら、内定承諾前にフォローアップ面談等の意思決定のご支援をいたしますのでお声がけください！'] },
        { step: 'STEP', number: '04', title: '入社日まで', text: ['入社日についてはご希望をお伺いして決定します。入社までの期間について、事前に業務理解や技術向上のために入社前に研修やチュートリアルの実施をお願いする場合がございます。'] },
    ];
    return (
        <div className='flow-content'>
            {flowItems.map((item, index) => (
                <div key={index} className='flow-item'>
                    <div className='flow-step en-font'>
                        <div>{item.step}</div>
                        <div className='flow-step-number'>{item.number}</div>
                    </div>
                    <div className='flow-details'>
                        <div className='flow-title'>
                            <span className='path-bullet'></span>
                            <span>{item.title}</span>
                        </div>
                        {item.text.map((line, i) => (
                            <p key={i} className={[1, 3].includes(i) ? 'ml' : ''}>{line}</p>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
};

const Welfare = () => {

    const BenefitSection = ({ title, items, icon }: { title: string, items: string[], icon: string }) => {
        return (
            <>
                <div className="benefit-section">
                    <div className='icon-container'>
                        <img className='icon' src={icon} alt="" />
                    </div>
                    <div className='text-container'>
                        <div className='title'>{title}</div>
                        {items.map((item, index) => (
                            <div className="content" key={index}>{item}</div>
                        ))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div className='left'>
                <BenefitSection
                    title="交通費"
                    items={["全額支給"]}
                    icon={WelfareIcon1}
                />
                <BenefitSection
                    title="昇給・賞与"
                    items={[
                        "昇給（査定による）/1月",
                        "システム部：通常賞与年2回 /6月・12月",
                        "営業部：決算賞与年1回（業績による）/12月"
                    ]}
                    icon={WelfareIcon2}
                />
                <BenefitSection
                    title="支援・補助制度"
                    items={[
                        "書籍購入補助",
                        "資格取得報酬金あり（社内規定あり）",
                        "資格取得支援制度"
                    ]}
                    icon={WelfareIcon3}
                />
            </div>
            <div className='right'>
                <BenefitSection
                    title="その他"
                    items={[
                        "定期健康診断",
                        "テレワーク導入（許可制）",
                        "オフィスカジュアル",
                        "副業OK（業務に支障がないレベル）",
                        "フリーアドレス制",
                        "各種勉強会",
                        "保養所あり",
                        "時短勤務OK（★ライフイベントに対応できる体制！　など）",
                        "出張手当",
                        "保険制度（健康保険、雇用保険、厚生年金、労災保険）",
                        "慶弔見舞金",
                        "夏2季休暇やバースデー休暇など、各種休暇制度あり",
                        "クラブオフ利用可能（外部福利厚生サービス）",
                        "社内旅行・季節によってなど各種イベントあり",
                        "グループ内の同好会・サークルあり",
                    ]}
                    icon={WelfareIcon4}
                />
            </div>
        </>
    );
};

export default function Recruit() {
    const { memberList, getList, apiError, eventImages, getEventImages } = useRecruit();

    useEffect(() => {
        getList();
        getEventImages();
    }, [getList, getEventImages]);

    const [activeTab, setActiveTab] = useState('sales');

    const handleSalesClick = () => {
        setActiveTab('sales');
    };

    const handleEngineerClick = () => {
        setActiveTab('engineer');
    };

    const shouldHideSection = apiError || (memberList && memberList.list.every(member => member.topics_id === 0));

    return (
        <>
            {/* PC画面 */}
            <div className="pc-content">
                <section className='top flex'>
                    <div className='text1 en-font'>Your Ideas,<br />Our Priority</div>
                    <img src={ImgMain} alt=""></img>
                    <div className='text2'>当社には成長の「舞台」も「機会」も「支援」も、すべてが揃っています。<br />社員一人ひとりが成長を望み、常に挑戦し続けるTEAM。<br />そんな仲間と共に、あなたも新たなステージへ踏み出しませんか？</div>
                </section>
                <section className='message flex'>
                    <div className='message-yellow en-font'>MESSAGE</div>
                    <div className='message-title'>地域No.1から日本、そして世界の舞台へ共に挑戦し成長をしましょう。</div>
                    <p className='message-content'>「技術が好き」「ITが好き」といった情熱を持ち、自分の可能性を広げたいと考えているあなたを心より歓迎します。<br />私たちは、あなたの夢やビジョンをサポートし、成長を後押しする環境を提供します。<br />新たな挑戦と可能性に満ちたチームで、一緒に未来を切り拓いていきましょう！</p>

                </section>
                <section className='career-path sec'>
                    <div className='section-title-upper en-font'>CAREER PATH</div>
                    <div className='section-title'>キャリアパスのイメージ</div>
                    <div className='ledger'>
                        <div className={`ledger-left ${activeTab === 'sales' ? 'active' : 'inactive'}`} onClick={handleSalesClick}>
                            <p>営業</p>
                        </div>
                        <div className={`ledger-right ${activeTab === 'engineer' ? 'active' : 'inactive'}`} onClick={handleEngineerClick}>
                            <p>エンジニア</p>
                        </div>
                        {activeTab === 'sales' ? <RecruitSales /> : <RecruitEngineer />}
                    </div>
                </section>
                <section className='flow sec'>
                    <div className='section-title-upper en-font'>FLOW</div>
                    <div className='section-title'>選考の流れ</div>
                    <Flow />
                </section>
                {!shouldHideSection && memberList && memberList.list.length > 0 && (
                    <>
                        <div className='margin-block'></div>
                        <section className='interview sec'>
                            <div className='section-title-upper en-font'>INTERVIEW</div>
                            <div className='section-title'>社員インタビュー</div>
                            <div className='interview-drawer flex'>
                                {memberList.list.map((member, index) => (
                                    <RecruitMemberCard items={member} className='member-card' key={index} />
                                ))}
                            </div>
                        </section>
                    </>
                )}
                <section className='welfare sec'>
                    <div className='section-title-upper en-font'>WELFARE</div>
                    <div className='section-title'>福利厚生</div>
                    <div className='welfare-container'>
                        <Welfare />
                    </div>

                </section>
                {
                    eventImages && eventImages?.list.length > 0 &&
                    <section className='events sec'>
                        <div className='section-title-upper en-font'>EVENTS</div>
                        <div className='section-title'>イベント</div>
                        <div className='event-img-area'>
                            {eventImages.list[0].event_images.map((image, index) => (
                                <div key={index} className='grid-box'>
                                    <img src={image.url_org} alt={image.desc} />
                                    <p>{image.desc}</p>
                                </div>
                            ))}
                        </div>
                    </section>
                }
            </div>

            {/* SP画面 */}
            <div className="sp-content">
                <section className='top flex'>
                    <div className='text1 en-font'>Your Ideas,<br />Our Priority</div>
                    <img src={ImgMainLeftSp} alt=""></img>
                    <img src={ImgMainRightSp} alt=""></img>
                    <div className='text2'>当社には成長の｢舞台｣も｢機会｣も｢支援｣も、<br />すべてが揃っています。<br />社員一人ひとりが成長を望み、<br />常に挑戦し続けるTEAM。<br />そんな仲間と共に、<br />あなたも新たなステージへ踏み出しませんか？</div>
                    {/* <div className='text2'>成長の「舞台」も「機会」も 「支援」も<br />すべてが整っています。<br />社員一人ひとりが成長を望んでおり、<br />常に挑戦し成長しつづける、<br />そんなTEAMの一員と<br />共に歩む仲間を募集しています。</div> */}
                </section>
                <section className='message flex'>
                    <div className='message-yellow en-font'>MESSAGE</div>
                    <div className='message-title'>地域No.1から日本、<br/>そして世界の舞台へ<br/>共に挑戦し<br/>成長をしましょう。</div>
                    <p className='message-content'>「技術が好き」「ITが好き」<br />といった情熱を持ち、<br />自分の可能性を広げたいと<br />考えているあなたを心より歓迎します。<br />私たちは、<br />あなたの夢やビジョンをサポートし、<br />成長を後押しする環境を提供します。<br />新たな挑戦と可能性に満ちたチームで、<br />一緒に未来を切り拓いていきましょう！</p>
                </section>
                <section className='career-path sec'>
                    <div className='section-title-upper en-font'>CAREER PATH</div>
                    <div className='section-title'>キャリアパスのイメージ</div>
                    <div className='ledger'>
                        <div className={`ledger-left ${activeTab === 'sales' ? 'active' : 'inactive'}`} onClick={handleSalesClick}>
                            <p>営業</p>
                        </div>
                        <div className={`ledger-right ${activeTab === 'engineer' ? 'active' : 'inactive'}`} onClick={handleEngineerClick}>
                            <p>エンジニア</p>
                        </div>
                        {activeTab === 'sales' ? <RecruitSalesSp /> : <RecruitEngineerSp />}
                    </div>
                </section>
                <section className='flow sec'>
                    <div className='section-title-upper en-font'>FLOW</div>
                    <div className='section-title'>選考の流れ</div>
                    <Flow />
                </section>
                {!shouldHideSection && memberList && memberList.list.length > 0 && (
                    <>
                        <div className='margin-block'></div>
                        <section className='interview sec'>
                            <div className='section-title-upper en-font'>INTERVIEW</div>
                            <div className='section-title'>社員インタビュー</div>
                            <div className='interview-drawer flex'>
                                {
                                    memberList.list.map((member, index) => (
                                        <RecruitMemberCard items={member} className='member-card' key={index} />
                                    ))
                                }
                            </div>
                        </section>
                    </>
                )}
                {apiError && (
                    <hr />
                )}
                <section className='welfare sec'>
                    <div className='section-title-upper en-font'>WELFARE</div>
                    <div className='section-title'>福利厚生</div>
                    <div className='welfare-container'>
                        <Welfare />
                    </div>
                </section>
                {
                    eventImages && eventImages?.list.length > 0 &&
                    <section className='events sec'>
                        <div className='section-title-upper en-font'>EVENTS</div>
                        <div className='section-title'>イベント</div>
                        <div className='event-img-area'>
                            {eventImages.list[0].event_images.map((image, index) => (
                                <div key={index} className='grid-box'>
                                    <img src={image.url_org} alt={image.desc} />
                                    <p>{image.desc}</p>
                                </div>
                            ))}
                        </div>
                    </section>
                }
            </div>
        </>
    );
}