/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

type TextBoxProps = {
    name?: string;
    hasError?: boolean;
    placeholder?: string;
    className?: string;
};

export default function TextBox(props: TextBoxProps) {

    const style = css`
        width: 100%;
        height: 4.167vw;
        border: 0.139vw solid ${props.hasError ? '#E84040' : '#EAEAEA'};
        border-radius: 0.417vw;

        &:focus {
            outline: 0.139vw solid #EAEAEA;
        }

        @media (max-width: 768px) {
            height: 13.333vw;
            border-width: 0.533vw;
            border-radius: 1.6vw;
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
        }
    `;

    return (
        <>
            <input type="text" css={style} name={props.name} />
        </>
    );
}