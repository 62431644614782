/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useWindowSize } from 'react-use';
import { RefObject, useState, useEffect } from 'react';

type ScheduleType = {
    time: string;
    subject: string;
    detail: string;
};

type DailyTimeScheduleProps = {
    targetRef: RefObject<HTMLDivElement>;
    data: ScheduleType;
    disableArrowDot?: boolean;
};

export default function DailyTimeSchedule (props: DailyTimeScheduleProps) {
    const { width, height } = useWindowSize();
    const [arrowDotHeight, setArrowDotHeight] = useState<number>(0);

    useEffect(() => {
        if (props.targetRef.current) {
            setArrowDotHeight(props.targetRef.current.getBoundingClientRect().height);
        }
    }, [props.targetRef, width, height]);

    const yellowPoint = css`
        width: 1.111vw;
        height: 1.111vw;
        background: #FFEC5F 0% 0% no-repeat padding-box;
        border-radius: 50%;
        min-width: 1.111vw;

        @media (max-width: 768px) {
            width: 4vw;
            height: 4vw;
            min-width: 4vw;
        }

        &::before {
            ${!props.disableArrowDot && `content: '';`}
            height: calc(${arrowDotHeight}px - 3.5vw);
            display: inline-block;
            border-left: 2px dotted #ccc;
            position: relative;
            top: 1.7vw;
            left: 0.45vw;

            @media (max-width: 768px) {
                height: calc(${arrowDotHeight}px - 9vw);
                border-left: 0.533vw dotted #ccc;
                top: 5.7vw;
                left: 1.7vw;
            }
        }
        &::after {
            ${!props.disableArrowDot && `content: '';`}
            display: inline-block;
            position: relative;
            top: 2.9vw;
            border: 0.4vw solid transparent;
            border-top: 0.6vw solid #ccc;
            box-sizing: border-box;

            @media (max-width: 768px) {
                top: 8.2vw;
                left: 0.7vw;
                border: 0.8vw solid transparent;
                border-top: 1.4vw solid #ccc;
            }
        }
    `;

    const scheduleWrap = css`
        padding-bottom: 4.167vw;

        @media (max-width: 768px) {
            padding-bottom: 10.667vw;
        }
    `;

    const scheduleTopArea = css`
        display: flex;
        align-items: center;
        margin-bottom: 1.111vw;

        @media (max-width: 768px) {
            margin-bottom: 4vw;
        }
    `;

    const scheduleTime = css`
        font-size: 1.528vw;
        letter-spacing: 0.076vw;
        margin-left: 1.111vw;
        margin-right: 2.778vw;

        @media (max-width: 768px) {
            font-size: 4.8vw;
            letter-spacing: 0.24vw;
            margin-left: 2.133vw;
            margin-right: 5.6vw;
            font-weight: 500;
        }
    `;

    const scheduleSubject = css`
        padding: 0.417vw 0.625vw 0.417vw 0;
        background: #444444 0% 0% no-repeat padding-box;;
        color: #fff;
        font-size: 1.389vw;
        letter-spacing: 0.069vw;
        min-height: 2.778vw;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
            padding: 0.8vw 1.2vw 0.8vw 0;
            font-size: 4.267vw;
            letter-spacing: 0.213vw;
            min-height: 8vw;
        }

        &::before {
            content: '';
            position: relative;
            left: -0.9vw;
            border: 0.4vw solid transparent;
            border-right: 0.7vw solid #444;
            box-sizing: border-box;

            @media (max-width: 768px) {
                left: -2.6vw;
                border: 1.3vw solid transparent;
                border-right: 2vw solid #444;
            }
        }
    `;

    const scheduleTextArea = css`
        font-size: 1.111vw;
        letter-spacing: 0.056vw;
        padding-left: 9.722vw;
        line-height: 2vw;

        @media (max-width: 768px) {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
            padding-left: 6.133vw;
            line-height: normal;
        }
    `;

    return (
        <div css={scheduleWrap} ref={props.targetRef}>
            <div css={scheduleTopArea}>
                <div css={yellowPoint}></div>
                <div css={scheduleTime} className='en-font-semi-bold'>{props.data.time}</div>
                <div css={scheduleSubject}>{props.data.subject}</div>
            </div>
            <div css={scheduleTextArea}>{props.data.detail}</div>
        </div>
    )
}