/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { HStack } from "./SharedLayout";
import { InputItem } from "../ContactFormRow";

type CheckBoxProps = {
    items: InputItem[];
    isSubmitted?: boolean;
    name?: string;
    className?: string;
};

export default function CheckBox(props: CheckBoxProps) {
    const wrap = css`
        margin-right: 2.778vw;
        color: #333;
        justify-content: center;
        align-items: center;

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 768px) {
            margin-right: 10.667vw;
        }
    `;

    const checkbox = css`
        width: 1.25vw;
        height: 1.25vw;
        min-width: 1.25vw;
        min-height: 1.25vw;
        border: 0.069vw solid #CCCCCC;
        border-radius: 0.278vw;
        margin: 0;
        -webkit-appearance: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.694vw;
        cursor: pointer;

        &:checked {
            color: #fff;
            border-color: #FF8900;
            background: #FF8900;
            border-radius: 0.278vw;
            &::after {
                content: '';
                display: inline-block;
                transform: rotate(45deg);
                position: relative;
                top: -0.06vw;
                width: 0.3vw;
                height: 0.6vw;
                border-bottom: 0.15vw solid #FFF;
                border-right: 0.15vw solid #FFF;
            }
        }

        @media (max-width: 768px) {
            width: 5.333vw;
            height: 5.333vw;
            min-width: 5.333vw;
            min-height: 5.333vw;
            border-radius: 1.067vw;
            border-width: 0.267vw;
            margin-right: 2.667vw;

            &:checked {
                border-radius: 1.067vw;
                &::after {
                    width: 1.2vw;
                    height: 2.2vw;
                    border-bottom: 0.55vw solid #FFF;
                    border-right: 0.55vw solid #FFF;
                    top: -0.3vw;
                }
            }
        }
    `;

    const label = css`
        font-size: 1.111vw;
        line-height: normal;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (max-width: 768px) {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
        }
    `;

    return (
        <>
            {
                props.items.map((item, index) => {
                    return (
                        <HStack key={index} css={wrap} className={props.className}>
                            <label css={label}>
                                <input type="radio" css={checkbox} name={props.name} value={item.id} />
                                {item.label}
                            </label>
                        </HStack>
                    )
                })
            }
        </>
    );
}