import { SubmitDataType } from "../api/useContact";

const identifierToJP = {
    ext_01: 'お問い合わせ種別',
    ext_02: 'ご氏名',
    ext_03: 'ご氏名（フリガナ）',
    email: 'メールアドレス',
    ext_05: '会社名',
    ext_08: '電話番号',
    ext_09: 'お問い合わせ詳細',
    ext_10: '新製品・サービスなどの各種新着情報のメール配信',
    ext_11: '個人情報の取り扱いについて同意する',
};

export const useContactFormValidation = () => {
    const errorMsg:any = [];
    const countError = (formData: SubmitDataType) => {
        let errorCount = 0;
        for (const key in formData) {
            switch (key) {
                case 'ext_01':
                case 'ext_02':
                case 'ext_03':
                case 'ext_05':
                case 'ext_09':
                case 'ext_10':
                case 'ext_11':
                    if (formData[key] === '' || formData[key] === 'null') {
                        errorCount = errorCount + 1;
                        errorMsg[key] = '※' + identifierToJP[key] + 'は必須項目です。';
                    }
                    break;
                case 'email':
                    if (formData[key] === '') {
                        errorCount = errorCount + 1;
                        errorMsg[key] = '※' + identifierToJP[key] + 'は必須項目です。';
                    } else {
                        const regex = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
                        if (!regex.test(formData[key])) {
                            errorCount = errorCount + 1;
                            errorMsg[key] = '※' + identifierToJP[key] + 'が正しくありません。';
                        }
                    }
                    break;
                case 'ext_08':
                    if (formData[key] === '') {
                        errorCount = errorCount + 1;
                        errorMsg[key] = '※' + identifierToJP[key] + 'は必須項目です。';
                    } else {
                        const regex = /\d{2,4}-\d{2,4}-\d{4}/;
                        if (!regex.test(formData[key])) {
                            errorCount = errorCount + 1;
                            errorMsg[key] = '※' + identifierToJP[key] + 'が正しくありません。';
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        return errorCount;
    };

    return { countError, errorMsg };
};