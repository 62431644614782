import { TopicPath } from "../components/parts/TopicTitlePath";
import Client from '../assets/images/about/value-1@2x.png';
import Member from '../assets/images/about/value-2@2x.png';
import Group from '../assets/images/about/value-3@2x.png';
import NumOfMembers from '../assets/images/about/data-1@2x.png';
import GenderRatio from '../assets/images/about/data-2@2x.png';
import AverageAge from '../assets/images/about/data-3@2x.png';
import GlobalTalent from '../assets/images/about/data-4@2x.png';
import Overtime from '../assets/images/about/data-5@2x.png';
import PaidVacation from '../assets/images/about/data-6@2x.png';
import PMark from '../assets/images/about/p-mark.webp';
import Pleasanter from '../assets/images/about/pleasanter.png';

export const TopicPathData:TopicPath[] = [
    { name: '株式会社はばたーく', link: '/' },
    { name: 'はばたーくとは？', link: '/about' },
];

interface ListProps {
    title: string;
    detail: string[];
};

export const ListData: ListProps[] = [
    {
        title: 'MISSION',
        detail: ['すべての人を “はばたかせる”']
    },
    {
        title: 'VISION',
        detail: ['Your Ideas, Our Priority（皆の思いを最優先に）']
    },
    {
        title: 'VALUE',
        detail: ['常に新たな挑戦を追い求める', 'すべての人の立場と利益を尊重する', 'グローバルな視野で考え行動する', '技術変革に応じて、常に最新情報を活用する']
    },
];

export interface AboutUsCardProps {
    image: string;
    title: string;
    titleEn: string;
    detail: string;
};

export const CardData: AboutUsCardProps[] = [
    {
        image: Client,
        title: '顧客',
        titleEn: 'CLIENT',
        detail: '当社は『対話する（Have a talk）』という重要なプロセスを通じて、お客様の課題・ニーズにしっかりと耳を傾けます。デジタル時代の多様なニーズに120%で応えることで、信頼されるDXソリューションパートナーとしてのポジションを確立していきます。'
    },
    {
        image: Member,
        title: '社員',
        titleEn: 'MEMBER',
        detail: 'ダイバーシティ＆インクルージョンを掲げ、多様なバックグラウンドを持つ社員が互いを尊重しながら成長する。ポジティブな行動を促進し、社員が最大限の可能性を発揮できるよう努めています。社員の意思や将来を大切にしながら、平等にチャンスと夢への環境を提供します。'
    },
    {
        image: Group,
        title: 'グループ',
        titleEn: 'GROUP',
        detail: 'アクログループのソリューション営業集団として、既存サービスの一層の強化に努め、お客様に新たな付加価値を提供します。これによりグループ全体の価値を高め、革新的なアプローチで新しい価値を創造していき、グループ全体のシナジーを最大限に引き出していきます。'
    },
];

interface NumbersCardProps {
    title: string;
    numbers: NumbersType[];
    image: string;
};

interface NumbersType {
    gender?: string;
    genderUnit?: string;
    genderRightNum?: string;
    unitJp?: string;
    unit?: string;
    rightNum?: string;
    genderLeftNum?: string;
    leftNum?: string;
}

export const NumbersCardData: NumbersCardProps[] = [
    {
        title: '従業員数',
        numbers: [
            { leftNum: '39', unitJp: '人' }
        ],
        image: NumOfMembers
    },
    {
        title: '男女比',
        numbers: [
            { gender: '男性', genderLeftNum: '79', genderRightNum: '.49', genderUnit: '%' },
            { gender: '女性', genderLeftNum: '20', genderRightNum: '.51', genderUnit: '%' },
        ],
        image: GenderRatio
    },
    {
        title: '平均年齢',
        numbers: [
            { leftNum: '32', rightNum: '.64', unitJp: '歳' },
        ],
        image: AverageAge
    },
    {
        title: 'グローバル人材割合',
        numbers: [
            { leftNum: '17', rightNum: '.95', unit: '%' },
        ],
        image: GlobalTalent
    },
    {
        title: '年間平均残業時間',
        numbers: [
            { leftNum: '6', rightNum: '.94', unitJp: '時間' },
        ],
        image: Overtime
    },
    {
        title: '有休消化率',
        numbers: [
            { leftNum: '73', rightNum: '.51', unit: '%' },
        ],
        image: PaidVacation
    },
];

export interface CompanyOverviewType {
    id: string;
    header: string;
    rowData: rowDataType[];
    image?: string;
}

interface rowDataType {
    text: string;
    text2?: string;
    link?: string;
    flexRow?: boolean;
    linkText?: string;
    rearText?: string;
    hasTab?: boolean;
}

export const CompanyOverviewData: CompanyOverviewType[] = [
    {
        id: 'company',
        header: '会社名',
        rowData: [
            { text: '株式会社はばたーく' }
        ]
    },
    {
        id: 'address',
        header: '所在地',
        rowData: [
            { text: '【東京オフィス】' },
            { text: '〒103-0023', hasTab: true },
            { text: '東京都中央区日本橋本町4-8-15', hasTab: true },
            { text: 'ネオカワイビル 5F', hasTab: true },
            { text: 'Google Map', link: 'https://goo.gl/maps/VYvX6CDhcvPdSHJG8', hasTab: true },
            { text: '【GT宮崎オフィス】' },
            { text: '〒880-0805', hasTab: true },
            { text: '宮崎県宮崎市橘通東4-1-4', hasTab: true },
            { text: '河北ビル5F AB室', hasTab: true },
            { text: 'Google Map', link: 'https://maps.app.goo.gl/5Qg4vZFqbAkyMFxi8', hasTab: true }
        ]
    },
    {
        id: 'tel',
        header: 'TEL',
        rowData: [
            { text: '【東京オフィス】' },
            { text: '03-4530-0076（代表）', hasTab: true },
            { text: '【GT宮崎オフィス】' },
            { text: '0985-41-5752', hasTab: true },
        ]
    },
    {
        id: 'founded',
        header: '設立',
        rowData: [
            { text: '2015年06月19日' }
        ]
    },
    {
        id: 'capital',
        header: '資本金',
        rowData: [
            { text: '3,000万円' }
        ]
    },
    {
        id: 'officer',
        header: '役員',
        rowData: [
            { text: '代表取締役', text2: '中村 和博' },
            { text: '取締役', text2: '島根 清高' },
            { text: '取締役', text2: '尾崎 高太' },
            { text: '取締役', text2: '今野 周太' },
            { text: '取締役', text2: '廣瀬 倫理（社外）' },
            { text: '取締役', text2: '有城 剛（社外）' },
            { text: '技術顧問', text2: '本間 紀史' },
        ]
    },
    {
        id: 'members',
        header: '従業員',
        rowData: [
            { text: '39名（営業12名 / エンジニア 25名 / 事務2名）' }
        ]
    },
    {
        id: 'works',
        header: '事業',
        rowData: [
            { text: 'DX推進支援' },
            { text: '開発支援' },
            { text: 'リソース支援' },
            { text: 'ソリューション・営業支援' },
            { text: '他、業務支援' },
        ]
    },
    {
        id: 'group',
        header: 'グループ会社',
        rowData: [
            { text: '株式会社 アクロホールディングス' }
        ]
    },
    {
        id: 'permitsAndLicenses',
        header: '許認可等',
        rowData: [
            { text: '一般派遣　厚生労働大臣許可' },
            { text: '派13-315902 (', link: `${process.env.REACT_APP_ROOT_IMG_URL}/files/user/マージン率.pdf`, linkText: '労働者派遣の状況', rearText: ')' },
        ],
        image: PMark
    },
    {
        id: 'partner',
        header: '認定パートナー',
        rowData: [
            { text: 'ローコード開発ツール' },
            { text: 'Pleasanter (', link: 'https://pleasanter.org/partner/haveatalk', linkText: 'プリザンター', rearText: ')' },
        ],
        image: Pleasanter
    },
];