/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface LinkProps {
    children: string;
    href: string;
    isHeaderPC?: boolean;
    className?: string;
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    letterSpacing?: string;
    lineHeight?: string;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    borderColor?: string;
};

const calculateHeaderHeightInPX = (headerHeightVW: number): number => {
    const viewportWidth = window.innerWidth;
    const headerHeightPX = (headerHeightVW / 100) * viewportWidth;
    return headerHeightPX;
};

export const scrollToSection = (id: string) => {
    const headerHeightPX = calculateHeaderHeightInPX(6.75);
    const element = document.getElementById(id);

    if (element) {
        const elementPosition: number = element.getBoundingClientRect().top;
        const offsetPosition: number = elementPosition + window.pageYOffset - headerHeightPX;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
};

export default function Link(props: LinkProps) {
    // Default values
    const defaultColor = '#333';
    const defaultMarginTop = '0px';
    const defaultMarginBottom = '0px';
    const defaultMarginLeft = '0px';
    const defaultMarginRight = '30px';
    const defaultFontSize = '14px';
    const defaultFontWeight = 'normal';
    const defaultLetterSpacing = '0.05em';
    const defaultLineHeight = '2vw';
    const defaultBorderColor = '#333';

    const [, setIsHover] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        if (props.isHeaderPC) {
            navigate('/');
            setTimeout(() => {
                scrollToSection(props.href);
            }, 100)
        } else {
            navigate(props.href);
            window.scrollTo({ top: 0 });
        }
    };

    const linkStyle = () => [
        css`
            position: relative;
            display: flex;
            align-items: center;
            width: fit-content;
            height: fit-content;
            cursor: pointer;
            margin-top: ${props.marginTop || defaultMarginTop};
            margin-bottom: ${props.marginBottom || defaultMarginBottom};
            margin-left: ${props.marginLeft || defaultMarginLeft};
            margin-right: ${props.marginRight || defaultMarginRight};
            color: ${props.color || defaultColor};
            font-size: ${props.fontSize || defaultFontSize};
            font-weight: ${props.fontWeight || defaultFontWeight};
            letter-spacing: ${props.letterSpacing || defaultLetterSpacing};
            line-height: ${props.lineHeight || defaultLineHeight};
            
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 0.069vw;
                background: ${props.borderColor || defaultBorderColor};
                transition: width 0.3s ease-in-out;
            }

            &:hover::before {
                width: 100%;
            }
        `
    ];

    return (
        <>
            <div
                css={linkStyle}
                onClick={handleClick}
                className={props.className}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                {props.children}
            </div>
        </>
    );
}
