import parse from 'html-react-parser';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMediaQuery, mediaQuery } from '../../hooks/useMediaQuery';

type DataType = {
    headText: string;
    headImage: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
}

type ImageAndTextContainerProps = {
    data: DataType;
    isImageLeft?: boolean;
    dividerColor?: 'yellow' | 'orange';
    marginBottom?: '82' | '142';
};

const defaultProps: ImageAndTextContainerProps = {
    data: {
        headText: '',
        headImage: '',
        text1: '',
        text2: '',
        text3: '',
        text4: '',
        text5: '',
    },
    isImageLeft: false,
};

export default function ImageAndTextContainer(props: ImageAndTextContainerProps = defaultProps) {
    const isSP = useMediaQuery(mediaQuery.sp);

    const styles = {
        imageAndTextContainer: css`
            color: #333;
            line-height: normal;
        `,
        headingImageWrap: css`
            display: flex;
            margin-bottom: 4.306vw;
            justify-content: space-between;
            ${props.isImageLeft && 'flex-direction: row-reverse;'}

            @media (max-width: 768px) {
                flex-direction: column;
                margin-bottom: 8vw;
            }
        `,
        textContents: css`
            max-width: 37.5vw;

            @media (max-width: 768px) {
                max-width: 100%;
            }
        `,
        headText: css`
            font-size: 2.222vw;
            letter-spacing: 0.111vw;
            margin-bottom: 2.639vw;

            @media (max-width: 768px) {
                font-size: 6.4vw;
                letter-spacing: 0.32vw;
                margin-bottom: 7.067vw;
            }
        `,
        colorDivider: css`
            width: 2.778vw;
            height: 2px;
            background: ${props.dividerColor === 'orange' ? '#FF8900' : '#FFE832'} 0% 0% no-repeat padding-box;

            @media (max-width: 768px) {
                width: 8vw;
                height: 0.533vw;
            }
        `,
        grayDivider: css`
            width: 37.5vw;
            height: 2px;
            background: #EAEAEA 0% 0% no-repeat padding-box;
            margin-bottom: 2.778vw;

            @media (max-width: 768px) {
                width: 100%;
                height: 0.533vw;
                margin-bottom: 8vw;
            }
        `,
        childText: css`
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            margin-bottom: 1.25vw;
            line-height: 1.855vw;

            @media (max-width: 768px) {
                font-size: 3.733vw;
                letter-spacing: 0.187vw;
                margin-bottom: 4vw;
                line-height: normal;
            }
        `,
        headerImage: css`
            width: 41.667vw;
            height: 27.778vw;
            object-fit: cover;

            @media (max-width: 768px) {
                width: 89.333vw;
                height: 59.733vw;
                margin-bottom: 8vw;
            }
        `,
        headBottomText: css`
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            margin-bottom: 1.25vw;
            line-height: 1.855vw;

            @media (max-width: 768px) {
                font-size: 3.733vw;
                letter-spacing: 0.187vw;
                margin-bottom: 4vw;
                line-height: normal;
            }
        `,
        marginB0: css`
            margin-bottom: 0 !important;
        `,
        marginB82: css`
            margin-bottom: 5.694vw;

            @media (max-width: 768px) {
                margin-bottom: 13.333vw;
            }
        `,
        marginB142: css`
            margin-bottom: 9.861vw;

            @media (max-width: 768px) {
                margin-bottom: 18.667vw;
            }
        `,
    }

    return (
        <>
            <div css={styles.imageAndTextContainer}>
                <div css={styles.headingImageWrap}>
                    <div css={styles.textContents}>
                        <div css={styles.headText}>{parse(props.data.headText)}</div>
                        <div className='flex'>
                            <div css={styles.colorDivider}></div>
                            <div css={styles.grayDivider}></div>
                        </div>
                        {
                            isSP &&
                            <img css={styles.headerImage} src={props.data.headImage} alt='' />
                        }
                        <div css={styles.childText}>{props.data.text1}</div>
                        <div css={styles.childText}>{props.data.text2}</div>
                        <div css={[styles.childText, styles.marginB0]}>{props.data.text3}</div>
                    </div>
                    {
                        !isSP &&
                        <img css={styles.headerImage} src={props.data.headImage} alt='' />
                    }
                </div>
                <div css={styles.headBottomText}>{props.data.text4}</div>
                <div css={[styles.headBottomText, props.marginBottom === '82' ? styles.marginB82 : styles.marginB142]}>{props.data.text5}</div>
            </div>
        </>
    );
}