import { apiClient } from "../lib/apiClient";
import { useState, useCallback } from "react";
import axios from "axios";

type PageInfo = {
    endPageNo: number;
    totalPageCnt: number;
}

type NewsListResponseApi = {
    errors: [];
    list: NewsDetailType[];
    messages: [];
    pageInfo: PageInfo;
}

type NewsDetailType = {
    topics_id: number;
    ymd: string;
    contents: string;
    subject: string;
    categories: CategoryType[];
}

export type CategoryType = {
    key: string;
    label: string;
}

type NewsDetailResponseApi = {
    errors: [];
    messages: [];
    details: NewsDetailType;
}

export type NewsListParams = {
    pageId: number;
    cnt?: number;
}

export const useNews = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [newsListData, setNewsListData] = useState<NewsListResponseApi>({
        errors: [],
        list: [],
        messages: [],
        pageInfo: {
            endPageNo: 0,
            totalPageCnt: 0,
        },
    });
    const [newsDetailData, setNewsDetailData] = useState<NewsDetailResponseApi>({
        errors: [],
        messages: [],
        details: {
            topics_id: 0,
            ymd: '',
            contents: '',
            subject: '',
            categories: [],
        },
    });

    const getList = useCallback(async (params: NewsListParams) => {
        const source = axios.CancelToken.source();
        setIsLoading(true);

        const body = {
            params: {
                pageID: params.pageId || 1,
                cnt: params.cnt || 10,
            },
            cancelToken: source.token,
        };

        await apiClient.get('/rcms-api/1/news/list', body)
        .then((response) => {
            setNewsListData(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const getDetail = useCallback(async (topicsId: number) => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        await apiClient.get(`/rcms-api/1/news/detail/${topicsId}`, { cancelToken: source.token })
        .then((response) => {
            setNewsDetailData(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const getPreview = useCallback(async (token: string) => {
        setIsLoading(true);
        await apiClient.get(`/rcms-api/1/news/preview?preview_token=${token}`, {})
        .then((response) => {
            setNewsDetailData(response.data);
        }).catch ((error) => {
            console.error('Error:', error);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    return { isLoading, newsListData, getList, newsDetailData, getDetail, getPreview };
}