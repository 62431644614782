/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { InputItem } from "../ContactFormRow";

type RadioButtonProps = {
    items: InputItem[];
    name?: string;
};

export default function RadioButton(props: RadioButtonProps) {
    const radioWrap = css`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2.708vw;

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 768px) {
            margin-right: 0;
            justify-content: unset;
            margin-bottom: 4.8vw;

            &:last-child {
                margin-bottom: 0;
            }
        }
    `;

    const radioStyle = css`
        width: 1.111vw;
        height: 1.111vw;
        border: 0.069vw solid #CCC;
        margin: 0;
        margin-right: 0.556vw;
        -webkit-appearance: none;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:checked {
            border-color: #FF8900;
            &::after {
                content: '';
                width: 0.5vw;
                height: 0.5vw;
                border: 1px solid #FF8900;
                border-radius: 50%;
                background-color: #FF8900;
            }
        }

        @media (max-width: 768px) {
            width: 5.333vw;
            height: 5.333vw;
            border: 0.267vw solid #CCC;
            margin-right: 2.667vw;

            &:checked {
                &::after {
                    width: 2.8vw;
                    height: 2.8vw;
                    border: 0.133vw solid #FF8900;
                }
            }
        }
    `;

    const labelStyle = css`
        font-size: 1.111vw;
        letter-spacing: 0.056vw;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (max-width: 768px) {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
        }
    `;

    return (
        <>
            {
                props.items.map((item, index) => {
                    return (
                        <div key={index} css={radioWrap}>
                            <label css={labelStyle}>
                                <input type="radio" css={radioStyle} name={props.name} value={item.id} />
                                {item.label}
                            </label>
                        </div>
                    )
                })
            }
        </>
    );
}