import './ServiceDetails.css'
import TopicTitlePath, { TopicPath } from '../components/parts/TopicTitlePath';
import { useSearchParams } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import parse from 'html-react-parser';
import { useMediaQuery, mediaQuery } from '../hooks/useMediaQuery';
import NotFound from '../components/NotFound';
import UnderlineButton from '../components/parts/UnderlineButton';
import { useService } from '../api/useService';
import Loading from '../components/parts/Loading';

export default function ServicePreview() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('preview_token');
    const { isLoading, serviceDetails, getPreview, serviceLists, getList } = useService();
    const isSP = useMediaQuery(mediaQuery.sp);

    useEffect(() => {
        if (token) getPreview(token);
        getList();
    }, [getPreview, getList, token]);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (!serviceDetails.details.topics_id) {
        return (
            <NotFound link='/service'>事業内容に戻る</NotFound>
        )
    }

    const details = serviceDetails.details;
    const topicPath: TopicPath[] = [
        { name: '株式会社はばたーく', link: '/' },
        { name: '事業内容', link: '/service' },
        { name: details.subject, link: `/service-details/${details.topics_id}` },
    ]

    return (
        <>
            {
                serviceDetails.details.topics_id &&
                <>
                    <TopicTitlePath topicSub={details.subject_en} topicMain={details.subject} topicPath={topicPath} />
                    <div className='part-1 flex-col'>
                        <div className='subject-en'>{parse(details.subject_en)}</div>
                        <div className='subject'>{parse(details.subject)}</div>
                        {
                            details.subtitle &&
                            <div className='subtitle'>{parse(details.subtitle)}</div>
                        }
                    </div>
                    <div className='part-2'>
                        <div className='card'>
                            <div className='flex head-text-wrap'>
                                <div className='yellow-point-m align-self-1'></div>
                                <div className='point1-title'>{details.point1_title}</div>
                            </div>
                            <div className='point1-text'>{details.point1_text}</div>
                            <div className='flex head-text-wrap'>
                                <div className='yellow-point-m align-self-2'></div>
                                <div className='point2-title'>{details.point2_title}</div>
                            </div>
                            <div className='point2-text'>{details.point2_text}</div>
                            {
                                details.point3_title && details.point3_text &&
                                <>
                                    <div className='flex head-text-wrap'>
                                        <div className='yellow-point-m align-self-3'></div>
                                        <div className='point3-title'>{details.point3_title}</div>
                                    </div>
                                    <div className='point3-text'>{details.point3_text}</div>
                                </>
                            }
                            <img className='image1' src={details.image1.url_org} alt='' />
                            {
                                details.highlights_text &&
                                <div className='highlights-area'>
                                    <div className='highlights-text'>{details.highlights_text}</div>
                                </div>
                            }
                            {
                                details.numeric_text &&
                                <div className='numeric-area'>
                                    {
                                        details.numeric_text.map((text, index) => (
                                            <Fragment key={index}>
                                                <div className='flex numeric-text-wrap'>
                                                    <div className='yellow-point-s'></div>
                                                    <div className='en-font number'>{`0${index + 1}.`}</div>
                                                    <div className='numeric-text'>{text}</div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                </div>
                            }
                            {
                                details.list_text &&
                                <div className='list-area'>
                                    {
                                        details.list_text.map((text, index) => (
                                            <Fragment key={index}>
                                                <div className='flex list-wrap'>
                                                    <div className='yellow-point-s'></div>
                                                    <div className='list-text'>{text}</div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                </div>
                            }
                            {
                                (details.image2?.id || details.image3?.id) &&
                                <div className='flex image-double'>
                                    <img className='image2' src={details.image2?.url_org} alt='' />
                                    <img className='image3' src={details.image3?.url_org} alt='' />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='part-3'>
                        <div className='en-font other-en'>OTHER</div>
                        <div className='other-service'>その他の事業内容</div>
                        {
                            isSP &&
                            <div className='other-text'>ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</div>
                        }
                        <div className={`card-area ${isSP ? 'flex-col' : 'flex'}`}>
                            {
                                serviceLists.list &&
                                serviceLists.list.filter(service => service.topics_id !== details.topics_id)
                                .map((service, index) => (
                                    <Fragment key={index}>
                                        <div className='card flex-col'>
                                            <div className='card-title'>{service.subject}</div>
                                            <div className='card-text'>{service.point1_text}</div>
                                            <UnderlineButton children='詳しく見る' link={`/service-details/${service.topics_id}`} className='card-action' />
                                        </div>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                </>
            }
        </>
    );
}