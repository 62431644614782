/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import OutlinedArrowWhite from '../../assets/images/outlined_arrow@2x.png';
import OutlinedArrowBlack from '../../assets/images/circled_rt_arrow_black_bg@2x.png';
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface UnderlineButtonProps {
    children: string;
    link: string;
    onClick?: () => void;
    className?: string;
    fontSize?: string;
    letterSpacing?: string;
    borderBottom?: string;
    marginRight?: string;
    marginTop?: string;
    paddingBottom?: string;
    circleWidth?: string;
    circleHeight?: string;
    justifyContent?: string;
}

const defaultValue = {
    fontSize: '1.111vw',
    letterSpacing: '0.056vw',
    borderBottom: '1px solid',
    marginRight: '0.556vw',
    marginTop: '0.556vw',
    paddingBottom: '0.556vw',
    circleWidth: '2.083vw',
    circleHeight: '2.083vw',
    justifyContent: 'center',
}

export default function UnderlineButton(props: UnderlineButtonProps) {
    const navigate = useNavigate();
    const [isHover, setIsHover] = useState<boolean>(false);

    const btnWrapStyle = () => [
        css`
            display: flex;
            align-items: center;
            justify-content: ${props.justifyContent || defaultValue.justifyContent};
        `
    ];

    const textStyle = () => [
        css`
            font-size: ${props.fontSize || defaultValue.fontSize};
            letter-spacing: ${props.letterSpacing || defaultValue.letterSpacing};
            margin-right: ${props.marginRight || defaultValue.marginRight};
            margin-top: ${props.marginTop || defaultValue.marginTop};
            padding-bottom: ${props.paddingBottom || defaultValue.paddingBottom};
            cursor: pointer;

            &:hover {
                border-bottom: ${props.borderBottom || defaultValue.borderBottom};
            }
        `
    ];

    const CircleStyle = () => [
        css`
            width: ${props.circleWidth || defaultValue.circleWidth};
            height: ${props.circleHeight || defaultValue.circleHeight};
        `
    ];

    const handleClick = () => {
        navigate(props.link);
        setTimeout(() => {
            window.scrollTo({ top: 0 });
        }, 100);
        if (props.onClick) props.onClick();
    }

    return (
        <div css={btnWrapStyle} className={props.className}>
            <div css={textStyle}
                onClick={handleClick}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >{props.children}</div>
            <img css={CircleStyle} src={isHover ? OutlinedArrowWhite : OutlinedArrowBlack} alt='' />
        </div>
    );
}