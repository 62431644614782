/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import defaultImage from "../../assets/images/service/img-dx@3x.png";
import { ReactNode } from "react";

interface ImageBlockProps {
    children?: ReactNode;
    className?: string;
    backgroundImage?: string;
};

export default function ImageBlock(props: ImageBlockProps) {
    // Default values
    const defaultBackgroundImage = defaultImage;

    const divStyle = () => [
        css`
            background-image: url(${props.backgroundImage || defaultBackgroundImage});
        `
    ];

    return (
        <>
            <div css={divStyle} className={props.className}>{props.children}</div>
        </>
    );
}
