/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import BgTopicTitle from "../../assets/images/common/bg_383838@2x.png";
import BgTopicPath from "../../assets/images/common/bg_444444@2x.png";
import Link from "./Link";
import React from "react";
import { useMediaQuery, mediaQuery } from "../../hooks/useMediaQuery";

interface TopicTitlePathProps {
    topicSub: string;
    topicMain: string;
    topicPath: TopicPath[];
}

export interface TopicPath {
    name: string;
    link: string;
}

function truncateName(name: string, maxLength: number): string {
    if (name.length > maxLength) {
        return name.slice(0, maxLength) + '...';
    }
    return name;
}

const styles = {
    topicTitleArea: css`
        background-image: url(${BgTopicTitle});
        background-size: cover;
        height: 9.653vw;
        color: #fff;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            height: 31.867vw;
        }
    `,
    topicTitleWrap: css`
        padding: 0 8.333vw;
        @media (max-width: 768px) {
            padding: 0 5.333vw;
        }
    `,
    topicSub: css`
        font-size: 0.764vw;
        margin-bottom: 0.764vw;
        letter-spacing: 0.076vw;
        white-space: nowrap;
        @media (max-width: 768px) {
            font-size: 2.667vw;
            letter-spacing: 0.267vw;
            margin-bottom: 2.667vw;
        }
    `,
    topicMain: css`
        font-size: 2.083vw;
        letter-spacing: 0.104vw;
        white-space: nowrap;
        @media (max-width: 768px) {
            font-size: 5.867vw;
            letter-spacing: 0.293vw;
            max-width: 80vw;
        }
    `,
    topicPathArea: css`
        background-image: url(${BgTopicPath});
        background-size: cover;
        letter-spacing: 0.042vw;
        color: #fff;
        padding: 0.833vw 8.333vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 768px) {
            padding: 5.2vw 5.333vw;
            letter-spacing: 0.173vw;
            flex-wrap: wrap;
        }
    `,
    arrowRight: css`
        width: 0.389vw;
        height: 0.389vw;
        margin: 0 0.729vw;
        border-top: 1.33px solid #AAA;
        border-right: 1.33px solid #AAA;
        transform: rotate(45deg);
        position: relative;
        top: 0.058vw;
        @media (max-width: 768px) {
            width: 1.412vw;
            height: 1.271vw;
            margin: 0 2.189vw;
            border-width: 0.254vw;
            top: 0.311vw;
        }
    ` 
}

export default function TopicTitlePath(props: TopicTitlePathProps) {
    const isSP = useMediaQuery(mediaQuery.sp);
    const maxLength = 77;

    return (
        <React.Fragment key={1}>
            <div css={styles.topicTitleArea}>
                <div css={styles.topicTitleWrap}>
                    <div className="en-font" css={styles.topicSub}>{props.topicSub}</div>
                    <div css={styles.topicMain}>{props.topicMain}</div>
                </div>
            </div>
            <div css={styles.topicPathArea}>
                {
                    props.topicPath.map((topic, index) => (
                        <React.Fragment key={index}>
                            {
                                isSP ? (
                                    <Link href={topic.link} color="#fff" fontSize="3.467vw" borderColor="#fff" marginRight="0px" lineHeight="normal">
                                        { topic.name }
                                    </Link>
                                ) : (
                                    <Link href={topic.link} color="#fff" fontSize="0.833vw" borderColor="#fff" marginRight="0px" lineHeight="normal">
                                        {truncateName(topic.name, maxLength)}
                                    </Link>
                                )
                            }
                            {
                                index < props.topicPath.length - 1 &&
                                <span css={styles.arrowRight}></span>
                            }
                        </React.Fragment>
                    ))
                }
            </div>
        </React.Fragment>
    );
}