import './NewsDetails.css'
import { Fragment, useEffect } from 'react';
import parse from 'html-react-parser'
import { useNavigate, useSearchParams } from 'react-router-dom';
import TopicTitlePath from '../components/parts/TopicTitlePath';
import { TopicPath } from "../components/parts/TopicTitlePath";
import Button from '../components/parts/Button';
import { useMediaQuery, mediaQuery } from '../hooks/useMediaQuery';
import { useNews } from '../api/useNews';
import NotFound from '../components/NotFound';
import Loading from '../components/parts/Loading';

export default function NewsPreview() {
    const navigate = useNavigate();
    const handleBackToList = () => {
        navigate('/news');
    };
    const [searchParams] = useSearchParams();
    const token = searchParams.get('preview_token');
    const isSP = useMediaQuery(mediaQuery.sp);
    const { isLoading, newsDetailData, getPreview } = useNews();
    const details = newsDetailData.details;

    useEffect(() => {
        if (token) getPreview(token);
    }, [getPreview, token])

    if (isLoading) {
        return (
            <Loading />
        );
    }

    if (!details.topics_id) {
        return (
            <>
                <NotFound link='/news'>ニュース一覧に戻る</NotFound>
            </>
        );
    }

    const TopicPathData: TopicPath[] = [
        { name: '株式会社はばたーく', link: '/' },
        { name: 'ニュース', link: '/news' },
        { name: details.subject, link: '/news-details/' + details.topics_id },
    ];

    return (
        <>
            <TopicTitlePath
                topicSub='NEWS'
                topicMain='ニュース'
                topicPath={TopicPathData}
            />
            <div className='news-details-site'>
                <div className='news-details-container'>
                    {
                        <Fragment key={details.topics_id}>
                            <div className='news-details-wrapper'>
                                <div className='news-details-upper flex'>
                                    <div className='date en-font'>{details.ymd.split('-').join('.')}</div>
                                    <div className='flex news-category-area'>
                                        {
                                            details.categories && 
                                            details.categories.map((category, index) => (
                                                <div key={index} className='tag'>{category.label}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='news-details-contents-wrap'>
                                    <div className='news-details-title'>{details.subject}</div>
                                    <hr className='horizontal'></hr>
                                    <div className='news-details-contents'>{parse(details.contents)}</div>
                                </div>
                                <div className='border'></div>
                            </div>
                        </Fragment>
                    }
                    {
                        isSP ? (
                            <Button onClick={handleBackToList} isBackArrow={true} widthPc='320px' heightPc='50px' circleMarginRPc='278px' circleWidthPc='30px' circleMarginRSp='70.667vw' marginTop='7.866vw' marginBottom='18.667vw'>ニュース一覧に戻る</Button>
                        ) : (
                            <Button onClick={handleBackToList} isBackArrow={true} widthPc='320px' heightPc='50px' circleMarginRPc='278px' circleWidthPc='30px' circleMarginRSp='70.667vw' marginBottom='7.5vw'>ニュース一覧に戻る</Button>
                        )
                    }
                </div>
            </div>
        </>
    );
}