/** @jsxImportSource @emotion/react */
import Button from "./parts/Button";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";

const styles = {
    notFoundContainer: css`
        background-image: url('../../assets/images/common/bg_grey.png');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 6.944vw;
        color: #333;
    `,
    noItem: css`
        font-size: 2.222vw;
        letter-spacing: 0.111vw;
        margin-bottom: 5.694vw;
    `,
};

export default function NotFound(props: { children: string, link: string }) {
    const navigate = useNavigate();
    const handleBackToList = () => {
        navigate(props.link);
    };

    return (
        <div css={styles.notFoundContainer}>
            <div css={styles.noItem}>ページが見つかりません。</div>
            <Button onClick={handleBackToList} isBackArrow={true} widthPc='320px' heightPc='50px' circleMarginRPc='278px' circleMarginRSp='71.273vw' circleWidthPc='30px' marginBottom='7.5vw'>
                {props.children}
            </Button>
        </div>
    );
}