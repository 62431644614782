import './Solution.css'
import TopicTitlePath, { TopicPath } from '../components/parts/TopicTitlePath';
import { useSolution, SolutionListType } from '../api/useSolution';
import { useEffect, useState, useRef } from 'react';
import Pagination from '../components/parts/Pagination';
import { HStack, VStack, Typography } from '../components/parts/SharedLayout';
import UnderlineButton from '../components/parts/UnderlineButton';
import Loading from '../components/parts/Loading';
import { useMediaQuery, mediaQuery } from '../hooks/useMediaQuery';
import Category from '../components/parts/Category';
import { CategoryType } from '../api/useSolution';

const SolutionCard = (props: { items: SolutionListType }) => {
    const isSP = useMediaQuery(mediaQuery.sp);
    const [isPreviewActive, setPreviewActive] = useState(false);
    const [isPaneHovered, setPaneHovered] = useState(false);

    const handleMouseOver = () => {
        setPreviewActive(true);
    };

    const handleMouseLeave = () => {
        if (!isPaneHovered) {
            setPreviewActive(false);
        }
    };

    const handlePaneMouseOver = () => {
        setPaneHovered(true);
    };

    const handlePaneMouseLeave = () => {
        setPaneHovered(false);
        if (!isPaneHovered) {
            setPreviewActive(false);
        }
    };

    const handleButtonClick = () => {
        setPreviewActive(!isPreviewActive);
    };

    return (
        <VStack className='solution-card'>
            <img src={props.items.image.url_org} alt='' className='solution-card-img' />
            {props.items.categories.length > 2 &&
                <>
                    <div className={`solution-card-img-category-overlay ${isPreviewActive || isPaneHovered ? 'active' : ''}`}
                        onMouseOver={handlePaneMouseOver}
                        onMouseLeave={handlePaneMouseLeave}
                    >
                        {props.items.categories.map((category, index) => (
                            <Category key={index} label={category.label} className='overlay' />
                        ))}
                    </div>
                    <div className={`solution-card-img-category-overlay-side ${isPreviewActive || isPaneHovered ? 'active' : ''}`}></div>
                </>
            }
            <HStack className='sol-tag-area'>
                <>
                    {props.items.categories.slice(0, 2).map((category, index) => (
                        <Category key={index} label={category.label} />
                    ))}
                    {props.items.categories.length > 2 &&
                        <Category label='...'
                            cursor='pointer'
                            onMouseOver={!isSP ? handleMouseOver : undefined}
                            onMouseLeave={!isSP ? handleMouseLeave : undefined}
                            onClick={isSP ? handleButtonClick : undefined}
                        />
                    }
                </>
            </HStack>
            <Typography className='solution-card-title'>{props.items.subject}</Typography>
            <Typography className='solution-card-desc'>{props.items.contents.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')}</Typography>
            {
                !isSP ?
                    <UnderlineButton children='詳しくはこちら' link={`/solution-details/${props.items.topics_id}`} className='solution-card-action' justifyContent='unset' fontSize='1.042vw' letterSpacing='0.052vw' circleWidth='1.667vw' circleHeight='1.667vw' />
                    : <UnderlineButton children='詳しくはこちら' link={`/solution-details/${props.items.topics_id}`} className='solution-card-action' justifyContent='unset' fontSize='3.733vw' letterSpacing='0.187vw' circleWidth='6.667vw' circleHeight='6.667vw' />
            }
        </VStack>
    );
};

export default function Solution() {
    const TOPIC_SUB = 'SOLUTION';
    const TOPIC_MAIN = 'ソリューション';
    const TOPIC_PATH: TopicPath[] = [
        { name: '株式会社はばたーく', link: '/' },
        { name: 'ソリューション', link: '/solution' }
    ];

    const { isLoading, solutionList, getList, getUsedCategory, categories, setCategories } = useSolution();
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);
    const keywordRef = useRef<HTMLInputElement>(null)
    const categoryRef = useRef<HTMLInputElement>(null)
    const [savedFilter, setSavedFilter] = useState<string>('');
    const [isCategoryFocused, setIsCategoryFocused] = useState<boolean>();
    const [suggestCategories, setSuggestCategories] = useState<CategoryType[]>();
    const [inputKeywordVal, setInputKeywordVal] = useState<string>('');
    const [inputCategoryVal, setInputCategoryVal] = useState<string>('');
    const [selectedCategories, setSelectedCategories] = useState<CategoryType[]>([]);
    const categoriesContainerRef = useRef<HTMLDivElement>(null);
    const [runCnt, setRunCnt] = useState<number>(0);

    useEffect(() => {
        if (runCnt === 0) {
            const savedPageNo = sessionStorage.getItem('currentSolutionPageNo');
            if (savedPageNo) {
                getList(Number(savedPageNo), savedFilter);
                setCurrentPageNo(Number(savedPageNo))
            } else {
                getList(currentPageNo, savedFilter);
            }
        
            getUsedCategory();
        }
        return () => {
            setRunCnt(runCnt + 1);
        }
    }, [currentPageNo, getUsedCategory, getList, savedFilter, solutionList.pageInfo.totalPageCnt, runCnt]);

    const handleChangePage = (pageNo: number) => {
        setCurrentPageNo(pageNo);
        sessionStorage.setItem('currentSolutionPageNo', pageNo.toString());
        getList(pageNo, savedFilter);
    }

    const searchKeyword = () => {
        sessionStorage.setItem('currentSolutionPageNo', '1');

        const filter = `keyword contains "${inputKeywordVal}"`
        const savedPageNo = sessionStorage.getItem('currentSolutionPageNo');

        getList(1, filter);
        setSavedFilter(filter);
        setInputCategoryVal('');
        setSelectedCategories([]);
        setSuggestCategories(categories);
        if (savedPageNo) setCurrentPageNo(Number(savedPageNo));
    }

    const handleKeywordChange = () => {
        let inputKeyword: string = '';
        if (keywordRef.current) {
            inputKeyword = keywordRef.current.value;
            setInputKeywordVal(inputKeyword);
        }
    }

    const searchCategory = () => {
        setIsCategoryFocused(false);
        sessionStorage.setItem('currentSolutionPageNo', '1');

        const targetKey = selectedCategories.map((category) => category.key);
        const filter = targetKey.length > 0 ? `categories in [${targetKey}]` : '';
        const savedPageNo = sessionStorage.getItem('currentSolutionPageNo');

        getList(1, filter);
        setSavedFilter(filter);
        setInputKeywordVal('');
        if (savedPageNo) setCurrentPageNo(Number(savedPageNo));
    }

    const handleCategoryFocus = () => {
        if (categoryRef.current?.value === '') {
            const items = suggestCategories ? suggestCategories : categories;
            setSuggestCategories(items);
        }
        setIsCategoryFocused(true);
    };

    const handleCategoryBlur = () => {
        setTimeout(() => {
            setIsCategoryFocused(false);
        }, 200);
    };

    const handleCategoryChange = () => {
        if (categoryRef.current) {
            setInputCategoryVal(categoryRef.current.value);
            const keysWithIsSelected: string[] = [];
            suggestCategories?.map(suggestCategory => {
                if (suggestCategory.isSelected) {
                    return keysWithIsSelected.push(suggestCategory.key)
                } else {
                    return []
                }
            })
            const newCategories = categories?.filter((item) => {
                if (keysWithIsSelected.includes(item.key)) {
                    item.isSelected = true;
                }
                return (categoryRef.current) && item.label.indexOf(categoryRef.current.value) !== -1
            })
            setSuggestCategories(newCategories);
        }
    }

    const selectCategory = (category: CategoryType) => {
        const isExist = selectedCategories.map((item) => item.key === category.key).includes(true);
        if (isExist) {
            removeCategory(category);
        } else {
            setIsSelected(category, true);
            selectedCategories.push(category);
            setIsCategoryFocused(false);
            setTimeout(() => {
                if (categoriesContainerRef.current) {
                    categoriesContainerRef.current.scrollLeft = categoriesContainerRef.current.scrollWidth;
                }
            }, 100)
        }
    }

    const removeCategory = (category: CategoryType) => {
        setIsSelected(category, false);
        const newSelectedCategories = selectedCategories.filter((item) => item.key !== category.key);
        setSelectedCategories(newSelectedCategories);
    }

    const setIsSelected = (category: CategoryType, flg: boolean) => {
        const newSuggestCategories = suggestCategories?.map((item) => {
            if (item.key === category.key) item.isSelected = flg;
            return item;
        })
        setSuggestCategories(newSuggestCategories);
        const newCategories = categories?.map((item) => {
            if (item.key === category.key) item.isSelected = flg;
            return item;
        })
        setCategories(newCategories);
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <TopicTitlePath topicSub={TOPIC_SUB} topicMain={TOPIC_MAIN} topicPath={TOPIC_PATH} />
            <VStack className="solution-area">
                <div className='search-box'>
                    <div className='keyword-search'>
                        <Typography className='search-subtitle en-font'>Keywords</Typography>
                        <Typography className='search-title'>キーワード検索</Typography>
                        <div className='flex keyword-form'>
                            <input
                                className='keyword-input'
                                name='keyword'
                                ref={keywordRef}
                                value={inputKeywordVal}
                                autoComplete={'off'}
                                onChange={handleKeywordChange}
                            />
                            <button className='keyword-btn' onClick={searchKeyword} />
                        </div>
                    </div>
                    <div className='category-search'>
                        <Typography className='search-subtitle en-font sp-margin-top'>Categories</Typography>
                        <Typography className='search-title'>カテゴリ検索</Typography>
                        <HStack className='keyword-form'>
                            <div className='flex multiselect-category-wrap' ref={categoriesContainerRef}>
                                {selectedCategories.length > 0 &&
                                selectedCategories.map((category, index) => (
                                    <span key={index} className='multiselect-category'>
                                        {category.label}
                                        <i className='multiselect-category-icon' onClick={() => removeCategory(category)}>&times;</i>
                                    </span>
                                ))}
                            </div>
                            <input
                                className='keyword-input category-input'
                                name='category'
                                ref={categoryRef}
                                onFocus={handleCategoryFocus}
                                onBlur={handleCategoryBlur}
                                onChange={handleCategoryChange}
                                autoComplete={'off'}
                                value={inputCategoryVal}
                            />
                            <button className='keyword-btn' onClick={searchCategory} />
                        </HStack>
                        {isCategoryFocused &&
                            <VStack className='select-box-area'>
                                {suggestCategories && suggestCategories.length > 0 ? (
                                    suggestCategories.map((category, index) => (
                                        <div
                                            key={index}
                                            className={`${category.isSelected ? 'selected-box' : 'select-box'}`}
                                            onClick={() => selectCategory(category)}
                                        >
                                            {category.label}
                                        </div>
                                    ))
                                ) : (
                                    <div className='select-box-nothing'>結果が見つかりません。</div>
                                )}
                            </VStack>
                        }
                    </div>
                    
                </div>
                {solutionList.list.length > 0 ? (
                    <HStack className='solution-card-area'>
                        {
                            <>
                                {solutionList.list.map((solution, index) => (
                                    <SolutionCard key={index} items={solution} />
                                ))}
                            </>
                        }
                    </HStack>
                ) : (
                    <Typography fontSize='15px' className='no-data-text'>該当する内容はございませんでした。</Typography>
                )}
                <Pagination currentPageNo={currentPageNo || solutionList.pageInfo.pageNo} lastPageNo={solutionList.pageInfo.totalPageCnt} onClick={handleChangePage} />
            </VStack>
        </>
    );
}