/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import RadioButton from "./parts/RadioButton";
import { HStack, VStack, Typography } from "./parts/SharedLayout";
import TextBox from "./parts/TextBox";
import TextArea from "./parts/TextArea";
import CheckBox from "./parts/CheckBox";
import { mediaQuery, useMediaQuery } from "../hooks/useMediaQuery";

type ContactFormRowProps = {
    title: string;
    inputType: 'radio' | 'textbox' | 'textarea' | 'checkbox';
    isSubmitted?: boolean;
    inputItems?: InputItem[];
    isRequired?: boolean;
    caution?: string;
    name?: string;
    errorMsg?: string;
    errorTextCenter?: boolean;
    marginBottom100?: boolean;
};

export type InputItem = {
    id: number;
    label: string;
    validate?: any;
}

export default function ContactFormRow(props: ContactFormRowProps) {
    const hasError = props.errorMsg ? true : false;
    const isPC = useMediaQuery(mediaQuery.pc);
    const formRow = css`
        align-items: center;
        color: #333;
        margin-bottom: 2.778vw;

        &:last-child {
            margin-bottom: 0;
        }
    `;

    const formLeft = css`
        min-width: 16.111vw;
    `;

    const requiredTag = css`
        background: ${props.isRequired ? '#FF8900' : '#AAA'} 0% 0% no-repeat padding-box;
        border-radius: 4px;
        color: #fff;
        width: 3.472vw;
        height: 1.389vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.833vw;
        font-weight: bold;
        letter-spacing: 0;
        margin-bottom: 0.694vw;

        @media (max-width: 768px) {
            border-radius: 1.067vw;
            width: 10.667vw;
            height: 5.333vw;
            font-size: 3.2vw;
            letter-spacing: 0;
            margin-bottom: 2.8vw;
        }
    `;

    const title = css`
        font-weight: bold;
        white-space: nowrap;

        @media (max-width: 768px) {
            margin-bottom: 2.667vw;
            white-space: normal;
            line-height: normal;
        }
    `;

    const formRight = css`
        min-width: 56.111vw;
        width: 100%;
        ${props.errorTextCenter && 'justify-content: center;'}
        align-items: center;
    `;

    const caution = css`
        margin-top: 0.764vw;

        @media (max-width: 768px) {
            margin-top: 2.533vw;
            width: 90%;
            line-height: normal;
        }
    `;

    const errorText = css`
        margin-top: 0.764vw;
        color: #E84040;
        ${props.errorTextCenter && 'align-self: center;'}

        @media (max-width: 768px) {
            margin-bottom: 2.533vw;
            line-height: normal;
        }
    `;

    return (
        <>
            {
                isPC ? (
                    <HStack css={formRow}>
                        <VStack css={formLeft}>
                            <div css={requiredTag}>{props.isRequired ? '必須' : '任意'}</div>
                            <Typography fontSize='1.111vw' letterSpacing='0.056vw' css={title}>{props.title}</Typography>
                        </VStack>
                        <VStack css={css`align-self: end;`}>
                            <HStack css={formRight}>
                                {
                                    props.inputType === 'radio' &&
                                    props.inputItems &&
                                    <RadioButton items={props.inputItems} name={props.name} />
                                }
                                {
                                    props.inputType === 'textbox' &&
                                    <VStack css={css`width: 100%;`}>
                                        <TextBox name={props.name} hasError={hasError} />
                                        {
                                            props.caution &&
                                            <Typography fontSize='0.903vw' letterSpacing='0.045vw' color='#999' css={caution}>{props.caution}</Typography>
                                        }
                                    </VStack>
                                }
                                {
                                    props.inputType === 'textarea' &&
                                    <TextArea name={props.name} hasError={hasError} />
                                }
                                {
                                    props.inputType === 'checkbox' &&
                                    props.inputItems &&
                                    <CheckBox items={props.inputItems} name={props.name} />
                                }
                            </HStack>
                            {
                                props.errorMsg &&
                                <>
                                    <Typography fontSize='0.903vw' letterSpacing='0.045vw' css={errorText}>{props.errorMsg}</Typography>
                                </>
                            }
                        </VStack>
                    </HStack>
                ) : (
                    <VStack>
                        <div css={requiredTag}>{props.isRequired ? '必須' : '任意'}</div>
                        <Typography fontSize='3.733vw' letterSpacing='0.187vw' css={title}>{props.title}</Typography>
                        {
                            props.errorMsg &&
                            <>
                                <Typography fontSize='3.2vw' letterSpacing='0.16vw' css={errorText}>{props.errorMsg}</Typography>
                            </>
                        }
                        <VStack css={css`margin-bottom: ${props.marginBottom100 ? '13.333vw' : '8vw;'}`}>
                            {
                                props.inputType === 'radio' &&
                                props.inputItems &&
                                <RadioButton items={props.inputItems} name={props.name} />
                            }
                            {
                                props.inputType === 'textbox' &&
                                <>
                                    <TextBox name={props.name} hasError={hasError} />
                                    {
                                        props.caution &&
                                        <Typography fontSize='3.2vw' letterSpacing='0.16vw' color='#999' css={caution}>{props.caution}</Typography>
                                    }
                                </>
                            }
                            {
                                props.inputType === 'textarea' &&
                                <TextArea name={props.name} hasError={hasError} />
                            }
                            {
                                props.inputType === 'checkbox' &&
                                props.inputItems &&
                                <HStack css={css`flex-wrap: wrap;`}>
                                    <CheckBox items={props.inputItems} name={props.name} />
                                </HStack>
                            }
                        </VStack>
                    </VStack>
                )
            }
        </>
    );
};