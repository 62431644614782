/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { MemberDetailType } from "../api/useRecruit";
import UnderlineButton from "./parts/UnderlineButton";
import { useMediaQuery, mediaQuery } from "../hooks/useMediaQuery";

type RecruitMemberCardProps = {
    items: MemberDetailType;
    onClick?: () => void;
    className?: string;
};

export default function RecruitMemberCard(props: RecruitMemberCardProps) {
    const isSP = useMediaQuery(mediaQuery.sp);

    const memberCard = css`
        width: 33.333vw;
        margin-left: 5.556vw;

        @media (max-width: 768px) {
            width: 89.333vw;
            margin-left: 0;
            margin-bottom: 10.667vw;
        }
    `;

    const memberImg = css`
        width: 33.333vw;
        height: 18.75vw;
        border-radius: 0.694vw;
        margin-bottom: 1.528vw;
        object-fit: cover;

        @media (max-width: 768px) {
            width: 89.333vw;
            height: 57.867vw;
            border-radius: 2.667vw;
            margin-bottom: 4.533vw;
        }
    `;

    const memberName = css`
        font-size: 1.389vw;
        letter-spacing: 0.069vw;
        margin-bottom: 0.486vw;
        font-weight: bold;

        @media (max-width: 768px) {
            font-size: 4.8vw;
            letter-spacing: 0.24vw;
            margin-bottom: 0.667vw;
        }
    `;

    const actionInfoArea = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `;

    const memberInfo = css`
        color: #666666;
        font-size: 1.042vw;
        letter-spacing: 0.052vw;

        @media (max-width: 768px) {
            font-size: 3.2vw;
            letter-spacing: 0.16vw;
        }
    `;

    return (
        <div css={memberCard} className={props.className}>
            <img src={props.items.main_image.url_org} alt="" css={memberImg} className="member-img" />
            <div css={memberName} className="member-name">{props.items.name}</div>
            <div css={actionInfoArea} className="action-info-area">
                <div css={memberInfo} className="member-info">{props.items.occupation + ' ' + props.items.entry_year + '年入社'}</div>
                {
                    isSP ? (
                        <UnderlineButton children='詳しく見る' link={`/recruit-details/${props.items.topics_id}`} onClick={props.onClick} fontSize='3.733vw' letterSpacing='0.187vw' circleWidth='8vw' circleHeight='8vw' marginRight='2.667vw' />
                    ) : (
                        <UnderlineButton children='詳しく見る' link={`/recruit-details/${props.items.topics_id}`} onClick={props.onClick} />
                    )
                }
            </div>
        </div>
    )
}