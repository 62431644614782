import { apiClient } from "../lib/apiClient";
import { useState, useCallback } from "react";
import axios from "axios";

type MemberListResponseApi = {
    errors: [];
    messages: [];
    list: MemberDetailType[];
}

type MemberDetailResponseApi = {
    errors: [];
    messages: [];
    details: MemberDetailType;
}

type EventImageListResponseApi = {
    errors: [];
    messages: [];
    list: EventImageList[];
}

export type MemberDetailType = {
    topics_id: number;
    ymd: string;
    name: string;
    occupation: string;
    entry_year: string;
    intro: string;
    main_image: {
        id: string;
        desc: string;
        url_org: string;
    },
    hotword: string;
    contents1_subject: string;
    contents1_image: {
        id: string;
        desc: string;
        url_org: string;
    },
    contents1_text: string[];
    contents2_subject: string;
    contents2_image: {
        id: string;
        desc: string;
        url_org: string;
    },
    contents2_text: string[];
    contents3_subject: string;
    contents3_image: {
        id: string;
        desc: string;
        url_org: string;
    },
    contents3_text: string[];
    time_schedule1: string[];
    time_schedule2: string[];
    time_schedule3: string[];
    time_schedule4: string[];
    time_schedule5: string[];
    time_schedule6: string[];
}

type EventImageList = {
    topics_id: number;
    event_images: EventImagesType[];
}

type EventImagesType = {
    id: string;
    desc: string;
    url_org: string;
}

export const useRecruit = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [memberList, setMemberList] = useState<MemberListResponseApi>({
        errors: [],
        messages: [],
        list: [
            {
                topics_id: 0,
                ymd: '',
                name: '',
                occupation: '',
                entry_year: '',
                intro: '',
                main_image: {
                    id: '',
                    desc: '',
                    url_org: '',
                },
                hotword: '',
                contents1_subject: '',
                contents1_image: {
                    id: '',
                    desc: '',
                    url_org: '',
                },
                contents1_text: [''],
                contents2_subject: '',
                contents2_image: {
                    id: '',
                    desc: '',
                    url_org: '',
                },
                contents2_text: [''],
                contents3_subject: '',
                contents3_image: {
                    id: '',
                    desc: '',
                    url_org: '',
                },
                contents3_text: [''],
                time_schedule1: [''],
                time_schedule2: [''],
                time_schedule3: [''],
                time_schedule4: [''],
                time_schedule5: [''],
                time_schedule6: [''],
            }
        ],
    });
    const [memberDetails, setMemberDetails] = useState<MemberDetailResponseApi>({
        errors: [],
        messages: [],
        details: {
            topics_id: 0,
            ymd: '',
            name: '',
            occupation: '',
            entry_year: '',
            intro: '',
            main_image: {
                id: '',
                desc: '',
                url_org: '',
            },
            hotword: '',
            contents1_subject: '',
            contents1_image: {
                id: '',
                desc: '',
                url_org: '',
            },
            contents1_text: [''],
            contents2_subject: '',
            contents2_image: {
                id: '',
                desc: '',
                url_org: '',
            },
            contents2_text: [''],
            contents3_subject: '',
            contents3_image: {
                id: '',
                desc: '',
                url_org: '',
            },
            contents3_text: [''],
            time_schedule1: [''],
            time_schedule2: [''],
            time_schedule3: [''],
            time_schedule4: [''],
            time_schedule5: [''],
            time_schedule6: [''],
        },
    });

    const [eventImages, setEventImages] = useState<EventImageListResponseApi>();
    const [apiError, setApiError] = useState(false);

    const getList = useCallback(async () => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        setApiError(false);

        await apiClient.get('/rcms-api/1/member/list', { cancelToken: source.token })
        .then((response) => {
            setMemberList(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
                setApiError(true);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const getDetail = useCallback(async (topicsId: string) => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        await apiClient.get(`/rcms-api/1/member/detail/${topicsId}`, { cancelToken: source.token })
        .then((response) => {
            setMemberDetails(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const getPreview = useCallback(async (token: string) => {
        setIsLoading(true);
        await apiClient.get(`/rcms-api/1/member/preview?preview_token=${token}`, {})
        .then((response) => {
            setMemberDetails(response.data);
        }).catch ((error) => {
            console.error('Error:', error);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const getEventImages = useCallback(async () => {
        setIsLoading(true);
        const source = axios.CancelToken.source();
        await apiClient.get(`/rcms-api/1/recruit/event-images`, { cancelToken: source.token })
        .then((response) => {
            setEventImages(response.data)
        }).catch((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, [])

    return { isLoading, memberList, getList, memberDetails, apiError, getDetail, getPreview, eventImages, getEventImages };
}