/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const HStack = (props: { children: React.ReactNode, className?: string }) => {
    const style = css`
        display: flex;
        flex-direction: row;
    `;

    return (
        <>
            <div className={props.className} css={style}>{props.children}</div>
        </>
    );
};

export const VStack = (props: { children: React.ReactNode, className?: string }) => {
    const style = css`
        display: flex;
        flex-direction: column;
    `;

    return (
        <>
            <div className={props.className} css={style}>{props.children}</div>
        </>
    );
};

export const Typography = (props: { children: string, className?: string, fontSize?: string, letterSpacing?: string, color?: string }) => {
    const style = css`
        font-size: ${props.fontSize || '0.972vw'};
        letter-spacing: ${props.letterSpacing || 'normal'};
        color: ${props.color || '#333'};
    `;

    return (
        <>
            <div className={props.className} css={style}>{props.children}</div>
        </>
    );
};

export const Space = (props: { height: string }) => {
    return (
        <div css={css`height: ${props.height};`}></div>
    )
}