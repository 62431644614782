/** @jsxImportSource @emotion/react */
import { FC, ReactNode, useState, useEffect } from "react";
import { css, keyframes } from "@emotion/react";


interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const modalStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const modalContentStyle = css`
  background-color: white;
  padding: 2.78vw;
  border-radius: 10px;
  box-sizing: border-box;
`;

const closeButtonStyle = css`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  float: right;
  color: #aaa;
  font-size: 2vw;
  font-weight: bold;
  &:hover {
    color: #FF8900;
    cursor: pointer;
  }
  &:active {
    color: #FF8900;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    font-size: 1.8vh;
  }
`;

const Modal: FC<ModalProps> = ({ show, onClose, children }) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setVisible(true);
    } else {
      const timer = setTimeout(() => setVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [show]);

  if (!visible) return null;

  return (
    <div
      css={[
        modalStyle,
        show ? css`animation: ${fadeIn} 0.5s ease` : css`animation: ${fadeOut} 0.5s ease`
      ]}
      onClick={onClose}
    > 
      <div
        css={[
          modalContentStyle,
          show ? css`animation: ${slideUp} 0.6s ease-out` : css`animation: ${slideDown} 0.6s ease-out`
        ]}
        onClick={(e) => e.stopPropagation()}
      >
        <button css={closeButtonStyle} onClick={onClose}>&times;</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;