/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface TagProps {
    label: string;
    className?: string;
    cursor?: string;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
}

export default function Category(props: TagProps) {
    const styles = () => [
        css`
            width: fit-content;
            height: 1.9vw;
            border: 0.069vw solid #FF8900;
            border-radius: 15px;
            color: #FF8900;
            font-size: 0.9vw;
            letter-spacing: 0.042vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.3vw;
            margin-bottom: 4px;
            padding: 0px 12px;
            cursor: ${props.cursor || 'default'};
    
            &:last-child {
                margin-right: 0;
            }
    
            @media (max-width: 768px) {
                height: 7.467vw;
                font-size: 3.2vw;
                letter-spacing: 0.16vw;
                margin-right: 1vw;
            }
        `
    ]

    return (
        <>
            <div css={styles} 
                className={props.className}
                onMouseOver={props.onMouseOver}
                onMouseLeave={props.onMouseLeave}
                onClick={props.onClick}
            >
                    {props.label}
                
            </div>
        </>
    )
}