/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { HStack } from "./SharedLayout";

type PaginationProps = {
    currentPageNo: number;
    lastPageNo: number;
    onClick: (pageNo:number) => void;
};

export default function Pagination(props: PaginationProps){
    const usePagination = (current: number, last: number) => {
        if (last < 1) 
            return [-1]

        if (last <= 7) {
            const pageArray: number[] = [];
            for (let i = 1; i <= last; i++) {
                pageArray.push(i);
            }
            return pageArray;
        }

        if (current >= 1 && current < 5)
            return [1, 2, 3, 4, 5, 0, last]

        if (current <= last && current > last-4)
            return [1, 0, last-4, last-3, last-2, last-1, last]

        return [1, 0, current-1, current, current+1, 0, last]
    };

    const handleClick = (page: number) => {
        props.onClick(page);
        window.scrollTo({
            top: 0,
            behavior:'smooth'
        })
    }

    const paginationDot = css`
        color: #999;
        margin-right: 0.7vw;
        letter-spacing: -0.3vw;
        font-size: 1vw;

        @media (max-width: 768px) {
            margin-right: 3vw;
            letter-spacing: -1.7vw;
            font-size: 4vw;
        }
    `;

    const pageBtn = css`
        width: 2.778vw;
        height: 2.778vw;
        background-color: #FFF;
        border: 2px solid #EAEAEA;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.972vw;
        letter-spacing: 0.097vw;
        color: #333;
        margin-right: 0.694vw;
        cursor: pointer;
        font-weight: 600;

        &:hover {
            opacity: 0.7;
        }

        &:active {
            background-color: #444;
            color: #fff;
            border: 1px solid #444;
        }

        @media (max-width: 768px) {
            width: 9.8vw;
            height: 9.8vw;
            font-size: 3.733vw;
            margin-right: 2vw;
            border: 3px solid #EAEAEA;

            &:active {
                border: 3px solid #444;
            }
        }
    `;

    const activePageBtn = css`
        background-color: #444;
        color: #fff;
        border: 1px solid #444;

        @media (max-width: 768px) {
            border: 4px solid #444;
        }
    `;

    const paginationWrap = css`
        justify-content: center;
        align-items: center;
    `;

    const arrowBtnWrap = css`
        width: 2.778vw;
        height: 2.778vw;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
            width: 3vw;
            height: 3vw;
            margin-right: 2vw;
        }
    `;

    const arrowBackBtn = css`
        cursor: pointer;
        width: 0.8vw;
        height: 0.8vw;
        border-top: 2px solid #999;
        border-right: 2px solid #999;
        border-radius: 1.5px;
        transform: rotate(225deg);

        @media (max-width: 768px) {
            width: 2.3vw;
            height: 2.3vw;
            border-top: 2px solid #999;
            border-right: 2px solid #999;
        }
    `;

    const arrowForwardBtn = css`
        cursor: pointer;
        width: 0.8vw;
        height: 0.8vw;
        border-top: 2px solid #999;
        border-right: 2px solid #999;
        border-radius: 1.5px;
        transform: rotate(45deg);

        @media (max-width: 768px) {
            width: 2.3vw;
            height: 2.3vw;
            border-top: 2px solid #999;
            border-right: 2px solid #999;
        }
    `;

    return (
        <>
            <HStack css={paginationWrap}>
                <div css={arrowBtnWrap}>
                    {
                        props.currentPageNo > 1 && (
                            <div css={arrowBackBtn} onClick={() => handleClick(props.currentPageNo - 1)}></div>  
                        )
                    }
                </div>
                {
                    usePagination(props.currentPageNo, props.lastPageNo).map((page, index) => {
                        return (
                            page > 0 ? (
                                <div key={index} className='en-font' onClick={() => handleClick(page)} css={[pageBtn, props.currentPageNo === page && activePageBtn]}>
                                    {page}
                                </div>
                            ) : 
                            page === 0 ? (
                                <div key={index} css={paginationDot}>・・・</div>
                            ) : (
                                <div key={index}></div>
                            )
                        )
                    })
                }
                <div css={arrowBtnWrap}>
                    {
                        props.currentPageNo !== props.lastPageNo && props.lastPageNo !== 0 && (
                            <div css={arrowForwardBtn} onClick={() => handleClick(props.currentPageNo + 1)}></div>
                        )
                    }
                </div>
            </HStack>
        </>
    );
}