import './Top.css'
import Button from '../components/parts/Button';
import UnderlineButton from '../components/parts/UnderlineButton';
import ImgRecruit1 from '../assets/images/top/img-recruit-1@2x.png';
import { useEffect } from 'react';
import parse from 'html-react-parser';
import { useMediaQuery, mediaQuery } from '../hooks/useMediaQuery';
import ImgMain from '../assets/images/top/img-main@2x.png';
import { useNews } from '../api/useNews';
import { useNavigate } from 'react-router-dom';

export default function Top() {
    const isSP = useMediaQuery(mediaQuery.sp);
    const { newsListData, getList } = useNews();
    const navigate = useNavigate();

    useEffect(() => {
        getList({ pageId: 1, cnt: 5 });
    }, [getList]);

    const movePage = (link: string) => {
        navigate(link);
        window.scrollTo({ top: 0 });
    };

    return (
        <>
            <section className='section-1'>
                <div id='main-vs' className='sub-sec-1'>
                    {
                        isSP &&
                        <img src={ImgMain} alt='main' className='img-main' />
                    }
                    <div className='content'>
                        <div className='text1'>｢対話｣と｢技術｣で<br />未来を切り拓く</div>
                        {
                            isSP ? (
                                <>
                                    <div className='text2'>お客様の期待に応えるDXソリューション提案</div>
                                    <div className='text3'>「対話（Have a talk）」を大切にし、DXソリューションで経営課題を解決。未来へ「はばたく」お手伝いをいたします。</div>
                                </>
                            ) : (
                                <>
                                    <div className='text2'>お客様の期待に応えるDXソリューション提案</div>
                                    <div className='text3'>「対話（Have a talk）」を大切にし、DXソリューションで経営課題を解決。<br />未来へ「はばたく」お手伝いをいたします。</div>
                                </>
                            )

                        }
                        <div className='actions'>
                            <Button children='ご相談・お問い合せはこちら' widthPc='31.944vw' heightPc='4.444vw' fontSizePc='1.25vw' widthSp='89.333vw' heightSp='13.333vw' fontSizeSp='3.733vw' />
                        </div>
                        <div className='text4 en-font'>CHANGE<span>S</span> TO GROW</div>
                    </div>
                </div>
                <section id='about' className='sub-sec-2'>
                    <div className='content'>
                        <div className='text1 en-font'>PHILOSOPHY</div>
                        {
                            isSP ? (
                                <>
                                    <div className='text2'>すべての人を<br />“はばたかせる”</div>
                                    <div className='text3'>関わる人を “はばたかせる”ために、<br />私たちは企業のDXを推進し<br />社会の課題を解決します。<br />日本のみならず、<br />グローバルな視点で活動し、<br />世界中で新たな価値を創造していきます。</div>
                                </>
                            ) : (
                                <>
                                    <div className='text2'>すべての人を “はばたかせる”</div>
                                    <div className='text3'>関わる人を “はばたかせる”ために、<br />私たちは企業のDXを推進し社会の課題を解決します。<br />日本のみならず、グローバルな視点で活動し、<br />世界中で新たな価値を創造していきます。</div>
                                </>
                            )
                        }
                        <div className='action'>
                            {
                                isSP ? (
                                    <UnderlineButton children='はばたーくとは？を詳しく見る' link='/about' fontSize='3.733vw' circleWidth='6.667vw' circleHeight='6.667vw' marginRight='1.6vw' />
                                ) : (
                                    <UnderlineButton children='はばたーくとは？を詳しく見る' link='/about' />
                                )
                            }
                        </div>
                    </div>
                </section>
            </section>
            <section id='service' className='section-2'>
                <div className='texts'>
                    <div className='text1 en-font'>BUSINESS</div>
                    <div className='text2'>事業内容</div>
                    <div className='text3'>当社は「DX」「ローカル・グローバル」「教育・リスキリング」の3つの軸でお客様のDXを推進するエコシステムを提供します。急速に深刻化するエンジニア不足に対して、ローカル・グローバルの活用、人材育成やリスキリングに注力します。</div>
                    <div className='text4'>これにより高い技術力と柔軟なソリューションを提供し、社会の変化に迅速に対応。お客様の持続的な成長を全面的に支援し、ビジネスの発展を共に目指します。</div>
                    {
                        isSP ? (
                            <UnderlineButton children='事業内容を詳しく見る' link='/service' justifyContent='unset' fontSize='3.733vw' letterSpacing='0.187vw' circleWidth='6.667vw' circleHeight='6.667vw' marginRight='1.6vw' />
                        ) : (
                            <UnderlineButton children='事業内容を詳しく見る' link='/service' justifyContent='unset' />
                        )
                    }
                </div>
            </section>
            <section id='recruit' className='section-3' >
                <img src={ImgRecruit1} alt="" />
                <div className='sub-sec'>
                    <div className='texts'>
                        <div className='text1 en-font'>RECRUIT</div>
                        <div className='text2'>採用情報</div>
                        <div className='text3'>一緒にお客様を未来に<br />はばたかせていく仲間を募集しています</div>
                    </div>
                    <Button children='採用情報を詳しく見る' onClick={() => movePage('/recruit')} widthPc='25vw' heightPc='4.167vw' fontSizePc='1.111vw' fontSizeSp='3.733vw' widthSp='89.333vw' heightSp='13.333vw' />
                </div>
            </section>
            <section className='section-4'>
                <div className='sub-sec-1 flex-col'>
                    <div className='text2 en-font text1'>NEWS</div>
                    {
                        !isSP &&
                        <UnderlineButton children='もっと見る' link='/news' justifyContent='unset' />
                    }
                </div>
                <div className='news-container flex-col'>
                    {
                        newsListData.list.map((news, index) => (
                            <div key={index} className='news-wrap' onClick={() => movePage(`/news-details/${news.topics_id}`)}>
                                <div className={`news-upper ${isSP ? 'flex-col' : 'flex'}`}>
                                    <div className='news-date en-font'>{news.ymd.split('-').join('.')}</div>
                                    <div className='tag-area'>
                                        {
                                            news.categories &&
                                            news.categories.slice(0, 2).map((category, index) => (
                                                <div key={index} className='tag'>{category.label}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className={`news-contents-wrap ${index === newsListData.list.length - 1 ? 'mb-0' : ''}`}>
                                    {parse((news.subject))}
                                </div>
                                {
                                    !isSP ? (
                                        index < newsListData.list.length - 1 &&
                                        <div className='border'></div>
                                    ) : (
                                        <div className='border'></div>
                                    )
                                }
                            </div>
                        ))
                    }
                    {
                        isSP &&
                        <div className='news-btn-sp'>
                            <UnderlineButton children='もっと見る' link='/news' justifyContent='unset' fontSize='3.733vw' letterSpacing='0.187vw' circleWidth='6.667vw' circleHeight='6.667vw' marginRight='1.467vw' />
                        </div>
                    }
                </div>
            </section>
        </>
    );
}