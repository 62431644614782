import './ContactSection.css'
import Button from './parts/Button';

export default function ContactSection() {
    return (
        <>
            {/* PC画面 */}
            <div className="pc-content">
                <section className='contact-area' id="contact">
                    <div className='title en-font'>CONTACT</div>
                    <div className='heading'>
                        <p>DX・自動化やシステム開発でお悩みでしたら</p>
                        <p>是非ご相談ください</p>
                    </div>
                    <div className='container white-area'>
                        <p className='sub-heading'>DX推進するエコシステムで経営課題を解決</p>
                        <Button marginBottom='2.778vw' circleWidthPc='40px'>ご相談・お問い合せはこちら</Button>
                    </div>
                    <div className='container black-area'>
                        <div className='business-content'>
                            <p className='tel-heading'>お電話でのお問い合せはこちら</p>
                        </div>
                        <div className='tel'>
                            <div className='call-icon'></div>
                            <a className='number en-font' href="tel:03-4530-0076">03-4530-0076</a>
                        </div>
                    </div>
                </section>
            </div>

            {/* SP画面 */}
            <div className="sp-content">
                <section className='contact-area'>
                    <div className='title en-font'>CONTACT</div>
                    <div className='heading'>
                        <p>DX・自動化やシステム開発で<br />お悩みでしたら</p>
                        <p>是非ご相談ください</p>
                    </div>
                    <div className='container white-area'>
                        <div className='sub-heading'>
                            <p>DX推進するエコシステムで<br />経営課題を解決</p>
                        </div>
                        <Button marginBottom='7.5vw'>ご相談・お問い合せはこちら</Button>
                    </div>
                    <div className='container black-area'>
                        <p className='tel-heading'>お電話でのお問い合せはこちら</p>
                        <div className='tel'>
                            <div className='call-icon'></div>
                            <a className='number en-font' href="tel:03-4530-0076">03-4530-0076</a>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}